<!-- 更多过滤中子组件 -->
<template>
	<div class="page-content">
		<div class="select-title pointer" @click="show">
			<div>
				<span v-if="!isShow" class="el-icon-plus icon-box" @click.stop="show"></span>
				<span v-if="isShow" class="el-icon-minus icon-box" @click.stop="show"></span>
				<el-checkbox v-model="checked" @change="allChecked">{{dataMoreFilterOne.propertyName}}</el-checkbox>
			</div>
			<div class="select-right" @click.stop>
				<span class="el-icon-search"></span>
				<!-- <el-color-picker size="mini" v-model="color" @change="changeColor" :show-alpha="true"
					color-format="rgb"></el-color-picker> -->
			</div>
		</div>
		<div class="select-content" :style="`height:${height}px`">
			<el-tree :data="dataMoreFilterOne.propertyValues" show-checkbox node-key="value" ref="tree"
				highlight-current @check="nodeClick" :props="defaultProps">
				<template slot-scope="{ node, data }">
					<p class="treeNode">
						<span class="label-box">{{data.valueCount[0]}}</span>
						<span>{{data.valueCount[1]}}</span>
					</p>
				</template>
			</el-tree>
		</div>
	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
export default {
  props: ['data', 'index'],
  data () {
    return {
      isShow: true,
      checked: false,
      height: 240,
      defaultProps: {
        children: 'children',
        label: 'value'
      },
      dataMoreFilterOne: {}
    }
  },
  mounted () {
    let val = JSON.parse(JSON.stringify(this.data))
    val.propertyValues = val.propertyValues.filter(e => {
      return e.elementGuid.length
    })
    this.dataMoreFilterOne = val
  },
  computed: {
    ...mapState('bimModel', {
      filterChecked: 'filterChecked',
      moreFilterData: 'moreFilterData',
      moreFilterChecked: 'moreFilterChecked'
    })
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        let val = JSON.parse(JSON.stringify(value))
        val.propertyValues = val.propertyValues.filter(e => {
          return e.elementGuid.length
        })
        this.dataMoreFilterOne = val
        // 这里的代码影响了循环出来的组件的状态的保存，需要修改一下
        // if (this.moreFilterChecked && this.moreFilterChecked.length) {
        // 	this.checkObj(this.moreFilterChecked)
        // } else {
        // 	this.checkObj()
        // }
      }
    }
  },
  methods: {
    ...mapMutations('bimModel', {
      initFilterChecked: 'initFilterChecked',
      initFilterObj: 'initFilterObj'
    }),
    allChecked (val) {
      let obj = {}
      let elementIds = []
      this.dataMoreFilterOne.propertyValues.map(item => {
        elementIds = elementIds.concat(item.elementId)
      })
      obj.propertyValues = elementIds
      obj.id = this.index
      obj.type = this.dataMoreFilterOne.type
      obj.all = true
      let filterChecked = this.filterChecked
      let index = filterChecked.findIndex(e => this.dataMoreFilterOne.propertyName == e.propertyName)
      // 全选事件
      if (val) {
        // 如果是全选事件
        this.$refs.tree.setCheckedNodes(this.dataMoreFilterOne.propertyValues)
        // 创建filterObj对象，把值赋给他
        obj.checked = true
        // 改变filterChecked
        if (index != -1) {
          filterChecked[index] = this.dataMoreFilterOne
        } else {
          filterChecked.push(this.dataMoreFilterOne)
        }
        // filterChecked.forEach(item => {
        // 	if(item.propertyName == )
        // })
        this.initFilterChecked(filterChecked)
        this.initFilterObj(obj)
      } else {
        // 如果是全部取消
        this.$refs.tree.setCheckedNodes([])
        obj.checked = false
        filterChecked.splice(index, 1)
        this.initFilterChecked(filterChecked)
        this.initFilterObj(obj)
      }
    },
    checkObj (data) {
      if (data) {
        const obj = data.find(a => a.name == this.dataMoreFilterOne.propertyName || a.name.split('@')[0] ==
						this.dataMoreFilterOne.propertyName)
        let arr = []
        if (obj) {
          obj.filterElementId.map(e => {
            const data = this.data.propertyValues.find(item2 => {
              return item2.elementId[0] == e
            })
            if (data) {
              arr.push(data.value)
            }
          })
        }
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(arr)
        })
      }
    },
    show () {
      if (this.isShow) {
        if (this.height >= 0) {
          this.height = 0
        }
      } else {
        if (this.height <= 240) {
          this.height = 240
        }
      }
      this.isShow = !this.isShow
    },
    clearCheck () {
      this.$refs.tree.setCheckedKeys([])
    },
    nodeClick (data, checkList) {
      let checked = true
      if (checkList.checkedKeys.findIndex(item => item == data.value) == -1) {
        checked = false
      }
      let cloneData = JSON.parse(JSON.stringify(this.data))
      let cList = this.$refs.tree.getCheckedNodes()
      cloneData.propertyValues = data.elementId
      cloneData.id = this.index
      // 如果该节点被选中了
      let filterChecked = this.filterChecked
      if (checked) {
        const findIndex = filterChecked.findIndex(item => {
          return item.propertyName == this.data.propertyName
        })
        let obj = null
        if (cList.length == 1) {
          cloneData.only = true
        }
        if (findIndex == -1) {
          // 如果没有这个对象
          obj = {
            propertyName: this.data.propertyName,
            type: this.data.type,
            propertyValues: [data]
          }
          // 找到应该放入的位置
          let newRightArr = this.moreFilterData.slice(0, this.index).reverse()
          console.log(newRightArr)
          let ind = -1
          for (let e of newRightArr) {
            ind = this.filterChecked.findIndex(item => item.propertyName == e.propertyName)
            if (ind != -1) {
              break
            }
          }
          if (ind == -1) {
            filterChecked.unshift(obj)
          } else {
            filterChecked.splice(ind + 1, 0, obj)
          }
          // filterChecked.push(obj)
        } else {
          filterChecked[findIndex].propertyValues.push(data)
        }
        cloneData.checked = true
        // 出现添加的时候去被取消的数据里面找，找到了把他过滤掉
        this.initFilterChecked(filterChecked)
      } else {
        // 当是因为过滤被触发的取消的时候，取消的不是剩下的
        // 取消的时候一定要保证取消的是所有的，所以这里需要重新去vuex中的moreFilterData里面拿一下、
        let dataList = JSON.parse(JSON.stringify(this.filterChecked))
        let indexC = dataList.findIndex(item => item.propertyName == this.data.propertyName)
        dataList[indexC].propertyValues = dataList[indexC].propertyValues.filter(e => e.value != data.value)
        if (dataList[indexC].propertyValues.length == 0) {
          dataList.splice(indexC, 1)
        }
        if (!cList.length) {
          cloneData.only = true
        }
        this.initFilterChecked(dataList)
        if (cList.length == this.dataMoreFilterOne.propertyValues.length) {
          // 此时选一下全选按钮
          this.checked = true
        } else {
          this.checked = false
        }
        cloneData.checked = false
      }
      this.initFilterObj(cloneData)
    },
    checkAutoMethods (keys) {
      this.$refs.tree.setCheckedKeys(keys)
    }

  }
}
</script>

<style lang="less" scoped>
	@import '../../../assets/css/model.less';

	.page-content {
		min-width: 300px;

		.pointer {
			cursor: pointer;
		}

		.treeNode {
			display: flex;
			justify-content: space-between;
			width: 220px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;

			.label-box {
				width: 160px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.select-title {
			border-top: 1px solid @borderColor;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 20px;
			font-weight: bold;
			box-sizing: border-box;
			padding: 10px 10px;
			border-bottom: 1px solid @borderColor;
			background-color: @backgroundTitle;

			.icon-box {
				font-size: 12px;
				margin-right: 10px;
			}

			.select-right {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 30px;
			}
		}

		.select-content {
			overflow-y: auto;
			transition: .5s;
		}
	}
</style>
