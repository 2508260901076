export function injectCSS (cssUrl, onSuccess, onError) {
  var href = cssUrl

  // Verify that we haven't downloaded it already
  var results = document.getElementsByTagName('link')
  for (var i = 0, len = results.length; i < len; i++) {
    if (results[i].href === href) {
      // Already downloaded
      return
    }
  }
  // else, download it
  var link = document.createElement('link')
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('type', 'text/css')
  link.setAttribute('href', href)
  link.addEventListener('load', function () {
    if (onSuccess && typeof (onSuccess) === 'function') {
      onSuccess()
    }
  }, false)
  link.addEventListener('error', function () {
    if (onError && typeof (onError) === 'function') {
      onError()
    }
  }, false)
  document.head.appendChild(link)
}

export function injectJsScript (jsUrl, onSuccess, onError) {
  var script = document.createElement('script')
  var head = document.getElementsByTagName('head')[0]
  script.type = 'text/javascript'
  script.src = jsUrl
  script.addEventListener('load', function () {
    if (onSuccess && typeof (onSuccess) === 'function') {
      onSuccess()
    }
  }, false)
  script.addEventListener('error', function () {
    if (onError && typeof (onError) === 'function') {
      onError()
    }
  }, false)
  head.appendChild(script)
}
