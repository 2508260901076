<!-- 左侧整个过滤组件 -->
<template>
  <div class="filter-page">
    <div class="filter-page-content">
      <div class="packup-content" :style="`width:${packupWidth}px`">
        <div class="head-content">
          <p
            class="iconfont icon-filter-full pointer"
            :class="{'chekButton' : isCheckFilter}"
            @click="showFilter"
          ></p>
          <p
            class="iconfont icon-caidan2 pointer"
            :class="{'chekButton' : isCheckProperty}"
            @click="showProperty"
          ></p>
        </div>
        <div class="bottom-content">
          <p
            class="el-icon-s-grid pointer"
            :class="{'chekButton' : isCheckTable}"
            @click="showTable"
          ></p>
        </div>
      </div>
      <div class="filter-content" :style="`width:${filterWidth}px`">
        <div class="left">
          <!-- <div class="head">
						<p></p>
						<p>一号楼</p>
						<p></p>
          </div>-->
          <div class="title">
            <div class="title-left">
              <p class="el-icon-s-data pointer"></p>
              <p>过滤器</p>
            </div>
            <el-button type="info" size="mini" @click="showMoreFilter">更多</el-button>
          </div>
          <!-- 左侧部分第一个选择器 -->
          <div class="selectOne select">
            <div class="select-title pointer">
              <el-checkbox v-model="checkFloorFlag" @change="checkAllFloor">{{filterOne.title}}</el-checkbox>
              <div class="select-right">
                <span class="el-icon-search"></span>
              </div>
            </div>
            <div class="select-content" @click.stop>
              <el-tree
                v-loading="loadingFloor"
                :data="filterOne.filterList"
                show-checkbox
                :props="floorProps"
                node-key="floorGuid"
                ref="floorTree"
                highlight-current
                @check="floorClick"
              >
                <template slot-scope="{ node, data }">
                  <p class="treeNode">
                    <span class="label-box">{{data.floorElementCount[0]}}</span>
                    <span>{{data.floorElementCount[1]}}</span>
                  </p>
                </template>
              </el-tree>
            </div>
          </div>
          <!-- 左侧部分空间选择器 -->
          <div class="selectThree select">
            <div class="select-title pointer">
              <el-checkbox v-model="checkSpaceFlag" @change="checkAllSpace">{{filterSpace.title}}</el-checkbox>
              <div class="select-right">
                <span class="el-icon-search"></span>
              </div>
            </div>
            <div class="select-content" @click.stop>
              <el-tree
                v-loading="loadingSpace"
                :data="filterSpace.filterList"
                show-checkbox
                :props="spaceProps"
                node-key="modelSpaceGuid"
                ref="spaceTree"
                highlight-current
                @check="spaceClick"
              >
                <template slot-scope="{ node, data }">
                  <p class="treeNode">
                    <span class="label-box">{{data.spaceElementCount[0]}}</span>
                    <span>{{data.spaceElementCount[1]}}</span>
                  </p>
                </template>
              </el-tree>
            </div>
          </div>
          <!-- 左侧部分第三个选择器 -->
          <div class="selectTwo select">
            <div class="select-title pointer">
              <el-checkbox v-model="checkedFamilyAndTypeFlag" @change="checkAllFamilyAndType">族与类型</el-checkbox>
              <div class="select-right">
                <span class="el-icon-search"></span>
              </div>
            </div>
            <div class="select-content" v-if="treeState">
              <el-tree
                v-loading="loadingFamilyAndType"
                :data="familyAndTypeList"
                show-checkbox
                node-key="dbid"
                ref="familyAndTypeTree"
                highlight-current
                @check="componentTypeClick"
              >
                <template slot-scope="{ node, data }">
                  <p class="treeNode">
                    <span class="label-box">{{data.elementCount[0]}}</span>
                    <span>{{data.elementCount[1]}}</span>
                  </p>
                </template>
              </el-tree>
            </div>
          </div>
          <div class="bottom">
            <p class="pointer" @click="clearFilter">清除过滤器</p>
          </div>
        </div>
        <!-- 右侧更多过滤 -->
        <div class="right" :style="`width:${moreFilterWidth}px`">
          <div class="head">
            <p>更多过滤</p>
            <el-link type="primary" :underline="false" @click="addAttribute">设置</el-link>
          </div>
          <div class="morefilter-content">
            <MoreFilter
              v-for="(item,index) in rightFliter"
              :ref="`morefilter${index}`"
              :key="item.propertyName"
              :data="item"
              :index="index"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreFilter from './MoreFilter.vue'
import EventBusModel from './eventBus/eventBusModel.js'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'FilterConponent',
  components: {
    MoreFilter
  },
  data () {
    return {
      packupWidth: 60,
      filterWidth: 600,
      moreFilterWidth: 300,
      showMore: true, // 控制更多过滤的状态
      showFilterFlag: true, // 控制过滤器
      checked: false,
      checkFloorFlag: false,
      checkSpaceFlag: false,
      checkedFamilyAndTypeFlag: false, // 控制族与类型的全选
      filterOne: {
        title: '楼层',
        filterList: []
      },
      filterSpace: {
        title: '空间',
        filterList: []
      },
      spaceProps: {
        children: 'children',
        label: 'modelSpaceName'
      },
      floorProps: {
        children: 'children',
        label: 'floorName'
      },
      rightFliter: [],
      copyMoreFilterData: [],
      oldFloorGuid: [],
      oldSpaceGuid: [],
      familyAndTypeList: [],
      yuansiFilter: [],
      treeState: true,
      loadingFloor: true,
      loadingSpace: true,
      loadingFamilyAndType: true,
      leftFilterResult: [],
      filterState: false,
      filterFamilyAndTypeState: false,
      isFilterFilterMoreFilterData: true,
      isCheckFilter: true,
      isCheckTable: true,
      isCheckProperty: false
    }
  },
  created () {
    this.getFloorData()
    this.getSpaceData()
  },
  mounted () {
    // EventBusModel.$on('checkLeftFilter', (floor, space, family, filterList, filterCheched) => {
    // 	this.checkLeftFilter(floor, space, family, filterList, filterCheched)
    // })
    EventBusModel.$on(
      'initFilterYansiData',
      (data, deleteEmdata, deleteRevit) => {
        this.addFilterData(data, deleteEmdata, deleteRevit)
      }
    )
    // 数据反选时间
    EventBusModel.$on(
      'restoringPageData',
      (
        newFloorData,
        checkFloorData,
        newSpaceData,
        checkSpaceData,
        newFamilyAndTypeData,
        checkFamilyAndTypeData,
        newMoreFilterData,
        checkMoreFilterData,
        yuansiMoreFilterData
      ) => {
        this.restoringPageData(
          newFloorData,
          checkFloorData,
          newSpaceData,
          checkSpaceData,
          newFamilyAndTypeData,
          checkFamilyAndTypeData,
          newMoreFilterData,
          checkMoreFilterData,
          yuansiMoreFilterData
        )
      }
    )
  },
  beforeDestroy () {
    EventBusModel.$off()
  },
  watch: {
    filterObj (val, oldVal) {
      if (val.id || val.id == 0) {
        this.filterMoreFilterData()
      }
    },
    categoryData (val, oldVal) {
      this.familyAndTypeList = JSON.parse(JSON.stringify(val))
      this.loadingFamilyAndType = false
    }
  },
  computed: {
    ...mapState('bimModel', {
      moreFilterData: 'moreFilterData',
      floorData: 'floorData',
      floorChecked: 'floorChecked',
      spaceData: 'spaceData',
      spaceChecked: 'spaceChecked',
      categoryData: 'categoryData',
      filterObj: 'filterObj',
      filterChecked: 'filterChecked',
      spaceData: 'spaceData',
      familyAndType: 'familyAndType',
      cacheState: 'cacheState'
    })
  },
  methods: {
    ...mapMutations('bimModel', {
      initFloorData: 'initFloorData',
      initSpaceData: 'initSpaceData',
      initFloorChecked: 'initFloorChecked',
      initSpaceChecked: 'initSpaceChecked',
      initFilterChecked: 'initFilterChecked',
      initFamilyAndType: 'initFamilyAndType',
      initReviewData: 'initReviewData',
      initMoreFilterData: 'initMoreFilterData'
    }),
    // 全选楼层
    checkAllFloor (val) {
      // val为是否全选
      let copySpaceData = JSON.parse(JSON.stringify(this.spaceData))
      let getData = []
      this.filterOne.filterList.map(item => {
        item.floorElementPropertyDtos.map(e => {
          getData.push(e)
        })
      })
      console.log(getData)

      if (val) {
        // 是全选的时候首先选中所有数据，界面层次
        this.$refs.floorTree.setCheckedNodes(this.filterOne.filterList)
        // 将所有楼层的数据放入vuex的楼层选中里面存储起来
        this.initFloorChecked(this.filterOne.filterList)
        // 过滤空间
        let elementIdList = []
        this.floorData.map(item => {
          elementIdList = elementIdList.concat(item.floorElementPropertyDtos)
        })

        // 拿出楼层中的所有elementId,然后拿去过滤空间中的数据
        let newSpaceData = this.filterSpaceMth(copySpaceData, elementIdList)
        this.filterSpace.filterList = newSpaceData
        // 再调用保存过滤的方法就可以对后续进行过滤，保存过滤的方法中有对族与类型与更多过滤中的过滤
        this.saveCheckedSpace(newSpaceData, getData)
      } else {
        // 如果是取消全选、即全都不选，那么首先将选中项从界面层次置为空
        this.$refs.floorTree.setCheckedNodes([])
        // 将vuex中保存的楼层选中数据置为空
        this.initFloorChecked([])
        // 空间中的数据根本不会被过滤，所以直接恢复初始数据就行了
        this.filterSpace.filterList = copySpaceData
        // 保存空间中的已选中项
        this.saveCheckedSpace(copySpaceData)
      }
    },
    // 空间中的全选
    checkAllSpace (val) {
      // val控制是否全选
      if (val) {
        // 将界面所有数据设置为全勋啊，将全部数据存入vuex中的已选
        this.$refs.spaceTree.setCheckedNodes(this.filterSpace.filterList)
        this.initSpaceChecked(this.filterSpace.filterList)
        // 调用过滤族与类型的时间
        this.filterFamilyAndType(0, this.filterSpace.filterList, false, true)
      } else {
        // 取消全选的时候，界面和vuex中的数据全部置为空
        this.$refs.spaceTree.setCheckedNodes([])
        this.initSpaceChecked([])
        // 根据楼层中的选中清空来判断如何处理族与类型
        // 如果楼层中有选中项，那就用楼层中的选中项去过滤族与类型
        if (this.floorChecked.length) {
          this.saveCheckedSpace(this.filterSpace.filterList)
        } else {
          this.filterFamilyAndType(-1)
          // -1就是恢复初始数据
        }
      }
    },
    // 族与类型的全选
    checkAllFamilyAndType (val) {
      if (val) {
        this.$refs.familyAndTypeTree.setCheckedNodes(this.familyAndTypeList)
        let arr = []
        // 拿出所有最底层的子级
        function getArr (data) {
          data.map(item => {
            if (!item.children.length) {
              let obj = {}
              let guidList = []
              let elementIdList = []
              item.elementList.map(e => {
                guidList.push(e.elementGuid)
                elementIdList.push(e.elementId)
              })
              obj.propertyName = item.label
              obj.elementId = elementIdList
              obj.elementGuid = guidList
              obj.dbid = item.dbid
              arr.push(obj)
            } else {
              return getArr(item.children)
            }
          })
        }
        getArr(this.familyAndTypeList)
        // 加将所有都村存进去
        this.initFamilyAndType(arr)
        // 拿出所有最底层数据然后去过滤右侧的
        this.filterCheckedFilterMoreFilter()
      } else {
        this.$refs.familyAndTypeTree.setCheckedKeys([])
        this.initFamilyAndType([])
        this.filterCheckedFilterMoreFilter()
      }
    },
    // 清空所有缓存的数据
    clearDataFilter () {
      // 清空所有数据
      this.rightFliter = []
      this.oldFloorGuid = []
      this.oldSpaceGuid = []
      // 恢复族与类型中给的数据
      this.familyAndTypeList = JSON.parse(JSON.stringify(this.categoryData))
      this.filterSpace.filterList = JSON.parse(JSON.stringify(this.spaceData))
      this.$refs.floorTree.setCheckedKeys([])
      this.$refs.spaceTree.setCheckedKeys([])
      this.$refs.familyAndTypeTree.setCheckedKeys([])
      this.yuansiFilter = []
    },
    // 在过滤器中选择了之后对右侧数据进行筛选
    filterCheckedFilterMoreFilter (type) {
      let listData = []
      let listelementGuid = []
      // 从最下层开始判断有没有，以最下面的为过滤标准进行过滤
      if (this.familyAndType.length) {
        this.familyAndType.map(item => {
          listData.push(...item.elementId)
          listelementGuid.push(...item.elementGuid)
        })
      } else if (this.spaceChecked.length) {
        this.spaceChecked.map(item => {
		  listData.push(...item.spaceElementPropertyDtos)
        })
      } else if (this.floorChecked.length) {
        this.floorChecked.map(item => {
          listData.push(...item.floorElementPropertyDtos)
        })
      }
      // 执行真正的过滤
      this.filterRightFliter(listData, listelementGuid)
    },
    // 过滤操作
    filterRightFliter (list, lsitdat) {
      let guidList = []
      console.log(list)

	  // 拿出所有的elementId

      if (list.length && !list[0].elementGuid) {
        guidList = list
      } else {
        list.map(e => {
		  guidList.push(e.elementId)
          lsitdat.push(e.elementGuid)
        })
      }
      let copyMoreFilterData = []
      console.log(this.yuansiFilter)
      copyMoreFilterData = JSON.parse(JSON.stringify(this.yuansiFilter))
      // 如果选择的是空，就统一还原
      if (!guidList.length) {
        for (let key in this.rightFliter) {
          this.$set(this.rightFliter, key, copyMoreFilterData[key])
        }
        this.saveChecked(-1, this.rightFliter)
        this.leftFilterResult = JSON.parse(JSON.stringify(this.rightFliter))
        return
      }
      // 不是空就执行过滤
      // 第一个参数是控制变量、第二个参数是
      this.rightFliter = this.loopFilter(
        -1,
        copyMoreFilterData,
        guidList,
        lsitdat
      )
      this.leftFilterResult = JSON.parse(JSON.stringify(this.rightFliter))
      this.saveChecked(-1, this.rightFliter)
    },
    // 过滤更多过滤中的数据
    loopFilter (ind, yuansiData, guid, listData) {
      function getData (elementIdList, filterElementIdList, elementGuid) {
        let newElementId = []
        let newElementGuid = []
        let newFilterElementId = []
        let indexfor = []
        for (const item in filterElementIdList) {
          let index = elementIdList.findIndex(
            e => e == filterElementIdList[item]
          )
          if (index != -1) {
            newElementId.push(elementIdList[index])
            newElementGuid.push(elementGuid[index])
            elementIdList.slice(index, 1)
          } else {
            indexfor.push(item)
            newFilterElementId.push(filterElementIdList[item])
          }
        }
        return [newElementId, newFilterElementId, newElementGuid, indexfor]
      }
      for (let index in yuansiData) {
        let nowList = JSON.parse(JSON.stringify(guid))
        console.log(nowList)
        if (nowList.length) {
          // 循环过滤
          yuansiData[index].propertyValues.forEach(item => {
            let arr = getData(item.elementId, nowList, item.elementGuid)
            item.elementId = arr[0]
            //  for(var i=0;i<arr[1].length;i++){
            // 	 item.elementId.push(arr[0][i])
            //  }

            nowList = arr[1]
            item.valueCount[1] = item.elementId.length
            item.elementGuid = arr[2]
		  })
          if (nowList.length) {
            let indecarr = []
            for (var i = 0; i < nowList.length; i++) {
              if (guid.indexOf(nowList[i]) != -1) {
                indecarr.push(guid.indexOf(nowList[i]))
              }
            }
            let gesearr = []
            indecarr.forEach(e => {
              gesearr.push(listData[e])
            })
            let arr = {
              elementGuid: [...gesearr],
              elementId: [...nowList],
              valueCount: ['(未包含)', nowList.length],
              value: '(未包含)'
            }
            yuansiData[index].propertyValues.push(arr)
          }
        }
      }
      return yuansiData
    },
    loopDelete (ind, yuansiData, guid) {
      let guids = guid
      function getData (elementIdList, filterElementIdList, elementGuid) {
        let newElementId = []
        let newElementGuid = []
        let newFilterElementId = []
        for (const item in elementIdList) {
          let index = filterElementIdList.findIndex(
            e => e == elementIdList[item]
          )
          if (index == -1) {
            newElementId.push(elementIdList[item])
            newElementGuid.push(elementGuid[item])
          } else {
            newFilterElementId.push(filterElementIdList[item])
          }
        }
        return [newElementId, newFilterElementId, newElementGuid]
      }
      for (let index in yuansiData) {
        guid = guids
        if (guid.length) {
          yuansiData[index].propertyValues.forEach(item => {
            let arr = getData(item.elementId, guid, item.elementGuid)
            item.elementId = arr[0]
            item.valueCount[1] = item.elementId.length
            item.elementGuid = arr[2]
            guid = arr[1]
          })
        }
	  }
      return yuansiData
    },
    // 用于选择了某一个之后去对之后的数据进行筛选
    filterMoreFilterData () {
      let yuansiData = JSON.parse(JSON.stringify(this.yuansiFilter)).slice(
        this.filterObj.id - 0 + 1
      )
      let guidList = this.filterObj.propertyValues
      let listData = []
      let lists = []
      yuansiData.forEach(e => {
        e.propertyValues.forEach(item => {
          listData.push(...item.elementGuid)
        })
      })
      if (this.filterObj.checked) {
        // 这个时候是当前点击的有被选中的
        let newData = this.loopFilter(this.filterObj.id, yuansiData, guidList, listData)
        // 合并一下和上次的数据，初次过滤的时候不合并
        if (
          this.isFilterFilterMoreFilterData ||
          this.filterObj.all ||
          this.filterObj.only
        ) {
          for (let index in this.rightFliter) {
            if (index > this.filterObj.id) {
              this.$set(
                this.rightFliter,
                index,
                newData[index - this.filterObj.id - 1]
              )
            }
          }
        } else {
          // 要合并
          for (let index in this.rightFliter) {
            if (index > this.filterObj.id) {
              // 进行合并操作,合并的是两个的propertyValues
              let propertyValues1 =
                newData[index - this.filterObj.id - 1].propertyValues
              let propertyValues2 = this.rightFliter[index].propertyValues
              let newArr = propertyValues2.concat(propertyValues1)
              let obj2 = {}
              newArr = newArr.reduce(function (item, next) {
                obj2[next.value]
                  ? ''
                  : (obj2[next.value] = true && item.push(next))
                return item
              }, [])
              let concatArr = propertyValues1.filter(item =>
                propertyValues2.find(e => e.value == item.value)
              )
              newArr.forEach(item => {
                let obj = concatArr.find(e => e.value == item.value)
                if (obj) {
                  item.elementGuid = item.elementGuid.concat(obj.elementGuid)
                  item.elementId = item.elementId.concat(obj.elementId)
                  item.valueCount[1] = item.elementGuid.length
                }
              })
              newData[index - this.filterObj.id - 1].propertyValues = newArr
              this.$set(
                this.rightFliter,
                index,
                newData[index - this.filterObj.id - 1]
              )
            }
          }
        }
        if (!this.filterObj.all && this.filterChecked.length) {
          this.isFilterFilterMoreFilterData = false
        } else {
          this.isFilterFilterMoreFilterData = true
        }
      } else {
        // 取消选中的时候
        // 如果一个选中都没了，直接恢复初始数据
        if (!this.filterChecked.length || this.filterObj.only) {
          // 如果选中为空
          this.rightFliter = this.leftFilterResult.length
            ? JSON.parse(JSON.stringify(this.leftFilterResult))
            : JSON.parse(JSON.stringify(this.yuansiFilter))
        } else {
          // 如果不是的话就执行过滤
          let deleteYuansiData = JSON.parse(
            JSON.stringify(this.rightFliter)
          )
          var newData = this.loopDelete(
            this.filterObj.id,
            deleteYuansiData,
            guidList
          )
          for (var index = 0; index < this.rightFliter.length; index++) {
            if (index > this.filterObj.id) {
              this.$set(
                this.rightFliter,
                index,
                newData[index]
                // newData[index - this.filterObj.id - 1]
              )
            }
          }
        }
        if (!this.filterObj.all && this.filterChecked.length) {
          this.isFilterFilterMoreFilterData = false
        } else {
          this.isFilterFilterMoreFilterData = true
        }
      }
      this.saveChecked(this.filterObj.id, this.rightFliter)
    },
    // 保留空间中的选择
    saveCheckedSpace (data, getdata) {
      this.$refs.spaceTree.setCheckedKeys([])
      let spaceChecked = JSON.parse(JSON.stringify(this.spaceChecked))
      let keys = []
      for (let index = 0; index < spaceChecked.length; index++) {
        let obj = data.find(
          e => e.modelSpaceName == spaceChecked[index].modelSpaceName
        )
        if (!obj) {
          spaceChecked.splice(index, 1)
          index -= 1
        } else {
          spaceChecked[index].spaceElementPropertyDtos =
            obj.spaceElementPropertyDtos
          keys.push(spaceChecked[index].modelSpaceGuid)
        }
      }
      this.initSpaceChecked(spaceChecked)
      this.$refs.spaceTree.setCheckedKeys(keys)
      let filterData = []
      if (this.spaceChecked.length) {
        filterData = this.spaceChecked
        console.log(filterData)
      } else {
        filterData = data
        console.log(filterData)
      }

      if (this.spaceChecked.length || this.floorChecked.length) {
        this.filterFamilyAndType(3, getdata)
      } else {
        // 传-1代表恢复初始数据
        this.filterFamilyAndType(-1, filterData)
      }
    },
    // 保留族与类型中的选择
    saveCheckedFamilyAndType (data) {
      this.$refs.familyAndTypeTree.setCheckedKeys([])
      let familyAndTypeData = JSON.parse(JSON.stringify(data))
      let familyAndTypeCheked = JSON.parse(JSON.stringify(this.familyAndType))
      let lists = []
      let dbids = []
      function findFamilyAndTypeChild (data) {
        data.map(e => {
          if (e.children.length) {
            return findFamilyAndTypeChild(e.children)
          } else {
            lists.push(e)
            dbids.push(e.dbid)
          }
        })
      }
      findFamilyAndTypeChild(familyAndTypeData)
      familyAndTypeCheked = familyAndTypeCheked.filter(
        item => dbids.findIndex(e => e == item.dbid) != -1
      )
      familyAndTypeCheked.forEach(e => {
        let obj = lists.find(p => p.dbid == e.dbid)
        let guidList = []
        let elementIdList = []
        obj.elementList.map(e => {
          guidList.push(e.elementGuid)
          elementIdList.push(e.elementId)
        })
        e.elementGuid = guidList
        e.elementId = elementIdList
      })
      let checkDbids = []
      familyAndTypeCheked.map(e => {
        checkDbids.push(e.dbid)
      })
      this.$refs.familyAndTypeTree.setCheckedKeys(checkDbids)
      this.initFamilyAndType(familyAndTypeCheked)
      this.filterCheckedFilterMoreFilter()
    },
    // 保留之前的选择
    async saveChecked (ind, data) {
      // 在保存过滤状态的时候别忘了对数据进行过滤,即每一个选中项要去从新过滤一下，主要针对更多过滤中
      let moreFilterData = JSON.parse(JSON.stringify(this.rightFliter)) // 经过过滤之后的右侧数据
      let filterChecked = JSON.parse(JSON.stringify(this.filterChecked)) // 右侧选中的数据
      // filterChecked = filterChecked.filter(item => moreFilterData.find(e => e.propertyName == item.propertyName))
      for (let index in moreFilterData) {
        // 如果当前的索引大于当前正在点击的
        // 去选中项的数据里面看一下当前数据存不存在选中
        let obj = filterChecked.find(
          e => e.propertyName == moreFilterData[index].propertyName
        )
        if (obj) {
          // 如果存在，就去给选中项拿到最新的数据
          obj.propertyValues.forEach(item => {
            let findObj = moreFilterData[index].propertyValues.find(
              e => e.value == item.value
            )
            item.elementGuid = findObj.elementGuid
            item.elementId = findObj.elementId
            item.valueCount = findObj.valueCount
          })
          obj.propertyValues = obj.propertyValues.filter(
            item => item.elementGuid.length
          )
          // 后面的每个数据要根据这个选中项过滤一次
          if (index - 0 + 1 <= moreFilterData.length) {
            let copyMoreFilter = JSON.parse(JSON.stringify(moreFilterData))
            moreFilterData.splice(index - 0 + 1)
            let elementIdList = []
            let elemetguid = []
            obj.propertyValues.map(e => {
              elementIdList.push(...e.elementId)
              elemetguid.push(...e.elementGuid)
            })
            moreFilterData.push(
              ...this.loopFilter(index, copyMoreFilter, elementIdList, elemetguid).slice(
                index - 0 + 1
              )
            )
          }
        }
      }
      this.rightFliter = moreFilterData
      filterChecked = filterChecked.filter(item => item.propertyValues.length)
      filterChecked.map(e => {
        let index = moreFilterData.findIndex(
          item => item.propertyName == e.propertyName
        )
        let keys = []
        e.propertyValues.map(p => {
          keys.push(p.value)
        })
        this.$refs[`morefilter${index}`][0].checkAutoMethods(keys)
      })
      for (let index in moreFilterData) {
        let obj = filterChecked.find(
          e => e.propertyName == moreFilterData[index].propertyName
        )
        if (obj) {
          let keys = []
          obj.propertyValues.map(p => {
            keys.push(p.value)
          })
          setTimeout(() => {
            this.$refs[`morefilter${index}`][0].checkAutoMethods(keys)
          }, 1)
        } else {
          setTimeout(() => {
            this.$refs[`morefilter${index}`][0].checkAutoMethods([])
          }, 1)
        }
      }
      await this.initFilterChecked(filterChecked)
      setTimeout(() => {
        this.lookTable()
      }, 1)
    },
    // 清除更多过滤中选中数据的方法
    clearMoreFilterData (index) {
      let key = 0
      let list = []
      if (index || index == 0) {
        key = index
        list = this.rightFliter.slice(index + 1)
      } else {
        key = -1
      }
      for (let ind in this.rightFliter) {
        if (ind > index) {
          this.$refs[`morefilter${ind}`][0].clearCheck()
        }
      }
      let datas = this.filterChecked.filter(
        item => list.findIndex(e => e.propertyName == item.propertyName) == -1
      )
      this.initFilterChecked(datas)
      if (!datas.length) {
        this.initReviewData([])
      }
    },
    // 获取楼层
    getFloorData () {
      this.$axios.get('/api/modeldata/getfloorcontainelementguid').then(res => {
        this.filterOne.filterList = res.filter(
          item => item.floorElementCount[1] != 0
        )
        this.initFloorData(res)
        this.loadingFloor = false
      })
    },
    // 获取空间
    getSpaceData () {
      this.$axios.get('/api/modeldata/getspacecontainelementguid').then(res => {
        this.filterSpace.filterList = res.filter(
          item => item.spaceElementCount[1] != 0
        )
        this.initSpaceData(res)
        this.loadingSpace = false
      })
    },
    // 楼层中的点击事件
    async floorClick (data, checkList) {
      let copySpaceData = JSON.parse(JSON.stringify(this.spaceData))
      let copyFilterData = JSON.parse(
        JSON.stringify(this.filterSpace.filterList)
      )
      this.filterSpace.filterList = []
      this.initFloorChecked(checkList.checkedNodes)
      // 在选择了楼层之后开始对空间中的数据进行筛选
      if (!checkList.checkedNodes.length) {
        this.filterSpace.filterList = copySpaceData
        this.saveCheckedSpace(copySpaceData)
        this.filterCheckedFilterMoreFilter()
        this.filterState = false
        return
      }
      let guidList = data.floorElementPropertyDtos
      let filterData = this.filterSpaceMth(copySpaceData, guidList)
      let newData = []
      if (checkList.checkedKeys.includes(data.floorGuid)) {
        // 如果是添加
        // 原始的和现在的合并
        // 如果数据还是初始状态，那么直接等于filterData就行
        if (!this.filterState) {
          // 添加并且是初始状态下的
          newData = filterData
        } else if (!filterData.length) {
          newData = copyFilterData
        } else {
          // 调用合并事件
          newData = this.oldConcatNew(
            1,
            copyFilterData,
            filterData,
            'modelSpaceGuid',
            'spaceElementPropertyDtos',
            'spaceElementCount'
          )
        }
      } else {
        // 如果是取消
        // 从原始数据中过滤掉现在的
        if (!filterData.length) {
          newData = copyFilterData
        } else {
          newData = this.oldConcatNew(
            0,
            copyFilterData,
            filterData,
            'modelSpaceGuid',
            'spaceElementPropertyDtos',
            'spaceElementCount'
          )
        }
      }
      // 判断一下全选状态
      if (this.floorChecked.length == this.floorData.length) {
        this.checkFloorFlag = true
      } else {
        this.checkFloorFlag = false
      }
      this.filterSpace.filterList = newData
      this.filterState = true
      // 保留空间中的选中，然后走这里面去过滤族与类型
      this.saveCheckedSpace(this.filterSpace.filterList, guidList)
      // 传1代表楼层，传0代表空间
      await this.filterCheckedFilterMoreFilter()
    },
    // 合并或者过滤,type == 1代表合并 ,type == 0 代表过滤
    oldConcatNew (type, oldData, newData, listName, oneName, spaceElementCount) {
      if (type) {
        // 如果是合并
        if (oldData && oldData.length) {
          let dataList = []
          oldData.map(item => {
            let obj = newData.find(e => e[listName] == item[listName])
            if (obj) {
              item[oneName].push(...obj[oneName])
              let obj2 = {}
              item[oneName] = item[oneName].reduce(function (item, next) {
                obj2[next.elementGuid]
                  ? ''
                  : (obj2[next.elementGuid] = true && item.push(next))
                return item
              }, [])
              item[spaceElementCount][1] = item[oneName].length
              dataList.push(obj)
            } else {
              dataList.push(item)
            }
          })
          newData.map(item => {
            if (dataList.findIndex(e => e[listName] == item[listName]) == -1) {
              dataList.push(item)
            }
          })
          return dataList
        } else {
          return newData
        }
      } else {
        // 如果是过滤
        // 走原始的里面删除现在的，要根据guid的数量来决定是过滤还是整个删除,直接调用过滤方法
        let filterData = []
        newData.map(e => {
          filterData.push(...e[oneName])
        })
        let dataList = this.filterSpaceMth(oldData, filterData, 1)
        return dataList
      }
    },
    // 对空间中的过滤
    filterSpaceMth (data, filterData, type) {
      // type == 1 保留 filterData里面没找到相同的elementId的数据
      if (type) {
        data.forEach(item => {
          item.spaceElementPropertyDtos = item.spaceElementPropertyDtos.filter(
            a => {
              return (
                filterData.findIndex(p => p.elementId == a.elementId) == -1
              )
            }
          )
          item.spaceElementCount[1] = item.spaceElementPropertyDtos.length
        })
      } else {
        // 否则就是保留找到了elementId的数据 ，用于多个过滤取消一个的时候
        data.forEach(item => {
          item.spaceElementPropertyDtos = item.spaceElementPropertyDtos.filter(
            a => {
              return (
                filterData.findIndex(p => p.elementId == a.elementId) != -1
              )
            }
          )
          item.spaceElementCount[1] = item.spaceElementPropertyDtos.length
        })
      }
      data = data.filter(a => {
        return a.spaceElementPropertyDtos.length
      })
      return data
    },
    // 空间中的点击方法
    spaceClick (data, checkList) {
      console.log(data)
      this.initSpaceChecked(checkList.checkedNodes)
      // 控制全选按钮是否被选中
      if (
        this.$refs.spaceTree.getCheckedNodes().length ==
        this.filterSpace.filterList.length
      ) {
        this.checkSpaceFlag = true
      } else {
        this.checkSpaceFlag = false
      }
      if (!checkList.checkedKeys.length && this.floorChecked.length) {
        this.filterFamilyAndType(0, this.filterSpace.filterList)
      } else {
        let checked = checkList.checkedKeys.includes(data.modelSpaceGuid)
        this.filterFamilyAndType(0, [data], true, checked)
      }
      this.filterCheckedFilterMoreFilter(1)
    },
    // 过滤族与类型的方法
    filterFamilyAndType (type, list, isClick, checked) {
      console.log(list)
      let datas = JSON.parse(JSON.stringify(this.categoryData))

      function getCount1 (item, arr) {
        console.log(arr)

        let count = 0
        // 遍历整个数组
        for (let i = 0; i < item.length; i++) {
          // 如果有children有值则向下递归
          if (item[i].children.length !== 0) {
            // 该元素的count为下一层的count的和
            item[i].count = getCount1(item[i].children, arr)
          } else {
            // 过滤
            item[i].elementList = item[i].elementList.filter(a => {
              return arr.findIndex(b => b === a.elementId) !== -1
            })
            item[i].count = item[i].elementList.length
          }
          item[i].elementCount[1] = item.count
          // 累加本层count
          count = count + item[i].count
          // 删除count为0的元素
          if (item[i].count === 0) {
            item.splice(i, 1)
            // 删除后索引改变，需要减1
            i--
          }
        }
        return count
      }

      function getFilterData (item, arr) {
        let count = 0
        // 遍历整个数组
        for (let i = 0; i < item.length; i++) {
          // 如果有children有值则向下递归
          if (item[i].children.length !== 0) {
            // 该元素的count为下一层的count的和
            item[i].count = getFilterData(item[i].children, arr)
          } else {
            // 过滤
            item[i].elementList = item[i].elementList.filter(a => {
              return arr.findIndex(b => b === a.elementId) == -1
            })
            item[i].count = item[i].elementList.length
          }
          item[i].elementCount[1] = item.count
          // 累加本层count
          count = count + item[i].count
          // 删除count为0的元素
          if (item[i].count === 0) {
            item.splice(i, 1)
            // 删除后索引改变，需要减1
            i--
          }
        }
        return count
      }
      let guidList = []
      if (type == -1) {
        this.familyAndTypeList = datas
        this.saveCheckedFamilyAndType(datas)
        return
      } else if (type == 1) {
        list.map(item => {
          item.floorElementPropertyDtos.map(e => {
            guidList.push(e.elementId)
          })
          console.log(guidList)
        })
        if (!guidList.length) {
          this.familyAndTypeList = datas
          this.saveCheckedFamilyAndType(datas)
          return
        }
      } else if (type == 3) {
        list.map(item => {
          guidList.push(item.elementId)
        })
      } else if (type != 1 && type != -1 && type != 3) {
        list.map(item => {
          item.spaceElementPropertyDtos.map(e => {
            guidList.push(e.elementId)
          })
        })

        if (!guidList.length) {
          this.filterFamilyAndType(1, this.floorChecked)
          return
        }
      }

      function getCount (item) {
        let count = 0
        // 遍历整个数组
        for (let i = 0; i < item.length; i++) {
          // 如果有children有值则向下递归
          if (item[i].children.length !== 0) {
            // 该元素的count为下一层的count的和
            item[i].count = getCount(item[i].children)
          }
          // 累加本层count
          count = count + item[i].count
          item[i].elementCount[1] = item[i].count
          // 删除count为0的元素
          if (item[i].count === 0) {
            item.splice(i, 1)
            // 删除后索引改变，需要减1
            i--
          }
        }
        return count
      }
      function mergeArr (arr1, arr2) {
        let arr3 = [].concat(arr1)
        for (let i = 0; i < arr2.length; i++) {
          // 是否重复的标志
          let flag = false
          for (let j = 0; j < arr1.length; j++) {
            if (arr2[i].dbid === arr1[j].dbid) {
              // 元素重复，改变flag
              flag = true
              if (arr3[j].children.length !== 0) {
                // 对元素的children相同的操作
                arr3[j].children = mergeArr(arr1[j].children, arr2[i].children)
              } else {
                // 最里层，对元素的elementList进行合并
                arr3[j].elementList = arr1[j].elementList.concat(
                  arr2[i].elementList
                )
                // console.log(arr3[j].elementList)
                // elementList去重
                let obj = {}
                arr3[j].elementList = arr3[j].elementList.reduce(function (
                  item,
                  next
                ) {
                  obj[next.elementId]
                    ? ''
                    : (obj[next.elementId] = true && item.push(next))
                  return item
                },
                [])
                // 改变elementCount的值
                arr3[j].elementCount[1] = arr3[j].elementList.length
                arr3[j].count = arr3[j].elementList.length
              }
              // 已经重复,结束循环
              break
            }
          }
          // 如果不重复，则直接添加元素
          if (!flag) {
            arr3.push(arr2[i])
          }
        }
        return arr3
      }

      if (isClick) {
        if (checked) {
          if (
            this.filterFamilyAndTypeState &&
            (this.spaceChecked.length != 1 || this.floorChecked.length != 1)
          ) {
            getCount1(datas, guidList)
            let newData = mergeArr(datas, this.familyAndTypeList)
            // 合并的第一步是走外层开始合并，把最新的里面有的，原来没有的给加上
            // 第二步是给合并完成之后的根据最新的去更新最底层
            getCount(newData)
            this.familyAndTypeList = newData
            this.saveCheckedFamilyAndType(newData)
          } else {
            getCount1(datas, guidList)
            getCount(datas)
            this.familyAndTypeList = datas
            this.saveCheckedFamilyAndType(datas)
          }
          // 这个变量控制是否是合并
          this.filterFamilyAndTypeState = true
        } else {
          if (this.spaceChecked.length) {
            let oldGuidList = []
            this.spaceChecked.map(item => {
              item.spaceElementPropertyDtos.map(e => {
                oldGuidList.push(e.elementId)
              })
            })
            guidList = guidList.filter(item => !oldGuidList.includes(item))
            let filterData = JSON.parse(JSON.stringify(this.familyAndTypeList))
            getFilterData(filterData, guidList)
            getCount(filterData)
            this.familyAndTypeList = filterData
            this.saveCheckedFamilyAndType(filterData)
          } else {
            console.log('执行')
            this.familyAndTypeList = datas
            this.saveCheckedFamilyAndType(datas)
            this.filterFamilyAndTypeState = false
          }
        }
      } else {
        getCount1(datas, guidList)
        getCount(datas)
        this.familyAndTypeList = JSON.parse(JSON.stringify(datas))
        this.saveCheckedFamilyAndType(datas)
      }
      // let endTime = new Date()
      // console.log('过滤族与类型中的时间'+(endTime - startTime))
      // getCount1(datas, guidList)
      // getCount(datas)
      // this.familyAndTypeList = JSON.parse(JSON.stringify(datas))
    },
    componentTypeClick (data, checkList) {
      console.log(data)
      // 使用递归找出最底层子集,只要最底层，其余的不要,最底层的guid
      let arr = []
      checkList.checkedNodes.map(item => {
        if (!item.children.length) {
          let obj = {}
          let guidList = []
          let elementIdList = []
          item.elementList.map(e => {
            guidList.push(e.elementGuid)
            elementIdList.push(e.elementId)
          })
          obj.propertyName = item.label
          obj.elementId = elementIdList
          obj.elementGuid = guidList
          obj.dbid = item.dbid
          arr.push(obj)
        }
      })
      console.log(arr)
      this.initFamilyAndType(arr)
      this.filterCheckedFilterMoreFilter(2)
    },
    // 添加更多过滤中的数据
    addFilterData (newData, deleteEmdata, deleteRevit) {
      let checkedList = JSON.parse(JSON.stringify(this.filterChecked))
      // 新增
      let newRightFilter = JSON.parse(JSON.stringify(this.yuansiFilter))
      let addData = newData.filter(item => {
        return !this.yuansiFilter.find(
          e => e.propertyName == item.propertyName && e.type == item.type
        )
      })
      newRightFilter.push(...addData)
      let newRightFilterEmdata = newRightFilter.filter(
        item => item.type == 'E'
      )
      let newRightFilterRevit = newRightFilter.filter(item => item.type == 'R')
      // 删除一下需要删除的
      if (deleteEmdata.length) {
        newRightFilterEmdata = newRightFilterEmdata.filter(
          item => deleteEmdata.findIndex(e => e == item.propertyName) == -1
        )
      }
      if (deleteRevit.length) {
        newRightFilterEmdata = newRightFilterEmdata.filter(
          item =>
            deleteEmdata.findIndex(e => e.propertyName == item.propertyName) ==
            -1
        )
      }
      checkedList.forEach(item => {
        item.propertyName = item.propertyName.split('@')[0]
      })
      // 看一下有没有重名的，如果有，就在名字后面加上后缀
      newRightFilterEmdata.forEach(item => {
        if (
          newRightFilterRevit.find(e => e.propertyName == item.propertyName)
        ) {
          let obj = checkedList.find(
            e => e.propertyName.split('@')[0] == item.propertyName
          )
          item.propertyName = item.propertyName + '@' + item.type
          if (obj) {
            obj.propertyName = item.propertyName
          }
        }
      })
      newRightFilterRevit.forEach(item => {
        if (
          newRightFilterEmdata.find(
            e =>
              e.propertyName == item.propertyName ||
              e.propertyName.split('@')[0] == item.propertyName
          )
        ) {
          let obj = checkedList.find(
            e => e.propertyName.split('@')[0] == item.propertyName
          )
          item.propertyName = item.propertyName + '@' + item.type
          if (obj) {
            obj.propertyName = item.propertyName
          }
        }
      })
      let newAddData = [...newRightFilterEmdata, ...newRightFilterRevit]
      // 得到全部的之后，删掉需要删掉的
      // newAddData = newAddData.filter(item => {
      // 	return deleteEmdata.find(e => e.propertyName == item.propertyName.split('@')[0]&& e.type == item.type) || deleteRevit.find(e => e.propertyName == item.propertyName.split('@')[0]&& e.type == item.type)
      // })
      let sortListData = []
      // 排序
      newData.map(e => {
        let obj = newAddData.find(
          item =>
            item.propertyName.split('@')[0] == e.propertyName.split('@')[0] &&
            item.type == e.type
        )
        if (obj) {
          sortListData.push(obj)
        }
      })
      // 过滤一下filterChecked
      checkedList = checkedList.filter(item =>
        sortListData.find(e => e.propertyName == item.propertyName)
      )
      this.initFilterChecked(checkedList)
      this.initMoreFilterData(sortListData)
      this.rightFliter = JSON.parse(JSON.stringify(sortListData))
      this.yuansiFilter = JSON.parse(JSON.stringify(sortListData))
      // 想办法过滤数据并且保留相关选中项
      // 第一步应该是根据左侧来保留数据
      if (
        this.floorChecked.length ||
        this.spaceChecked.length ||
        this.familyAndType.length
      ) {
        this.filterCheckedFilterMoreFilter()
      }
      this.saveChecked(-1, this.rightFliter)
    },
    // 更新报表数据
    showTable () {
      this.isCheckTable = !this.isCheckTable
      this.$emit('showTable')
    },
    showFilter () {
      if (this.showFilterFlag) {
        // 如果已经展开了就收起
        this.filterWidth = 0
        this.$emit('changeTableWidth', 1860, 60)
      } else {
        if (this.showMore) {
          this.filterWidth = 600
          this.$emit('changeTableWidth', 1260, 660)
        } else {
          this.filterWidth = 300
          this.$emit('changeTableWidth', 1560, 360)
        }
      }
      this.isCheckFilter = !this.isCheckFilter
      this.showFilterFlag = !this.showFilterFlag
    },
    showProperty () {
      this.$emit('showProperty')
    },
    hiddenFilter () {
      this.showMore = false
      if (this.filterWidth > 0) {
        this.filterWidth = 0
        this.moreFilterWidth = 0
      } else {
        this.moreFilterWidth = 0
      }
      if (this.packupWidth <= 60) {
        this.packupWidth = 60
      }
      this.$emit('changeTableWidth', 1860)
    },
    showMoreFilter () {
      if (!this.showMore) {
        if (this.filterWidth <= 690) {
          this.filterWidth = 600
          this.moreFilterWidth = 300
        }
        this.$emit('changeTableWidth', 1260, 660)
      } else {
        if (this.filterWidth >= 300) {
          this.filterWidth = 300
          this.moreFilterWidth = 0
        }
        this.$emit('changeTableWidth', 1560, 360)
        // this.showMore = false
      }
      this.showMore = !this.showMore
    },
    // 点击点击设置按钮
    addAttribute () {
      if (!this.cacheState) {
        this.$message.warning('数据正在初始化，请稍候')
        return
      }

      EventBusModel.$emit('addAttribute', 1)
    },
    // 选中已保存的表格之后的反选事件
    restoringPageData (
      newFloorData,
      checkFloorData,
      newSpaceData,
      checkSpaceData,
      newFamilyAndTypeData,
      checkFamilyAndTypeData,
      newMoreFilterData,
      checkMoreFilterData,
      yuansiMoreFilterData
    ) {
      this.yuansiFilter = JSON.parse(JSON.stringify(yuansiMoreFilterData))
      this.initMoreFilterData(yuansiMoreFilterData)
      // 楼层数据不会变化，所以不用重新赋值
      // 拿出楼层选中项的key
      let floorCheckKeys = []
      checkFloorData.map(item => {
        floorCheckKeys.push(item.floorGuid)
      })
      // 空间数据重新赋值
      this.filterSpace.filterList = newSpaceData
      // 拿出空间数据被选中的key
      let spaceCheckKeys = []
      checkSpaceData.map(item => {
        spaceCheckKeys.push(item.modelSpaceGuid)
      })
      // 族与类型数据重新赋值
      this.familyAndTypeList = newFamilyAndTypeData
      // 拿出族与类型被选中的key
      let familyAndTypeCheckKeys = []
      checkFamilyAndTypeData.map(item => {
        familyAndTypeCheckKeys.push(item.dbid)
      })
      // 更多过滤数据赋值
      this.rightFliter = newMoreFilterData
      // 反选楼层数据
      // 反选空间数据
      // 反选族与类型数据
      // 反选更多过滤数据
      this.$nextTick(() => {
        this.$refs.floorTree.setCheckedKeys(floorCheckKeys)
        this.$refs.spaceTree.setCheckedKeys(spaceCheckKeys)
        this.$refs.familyAndTypeTree.setCheckedKeys(familyAndTypeCheckKeys)
        for (let index in checkMoreFilterData) {
          let checkKeys = []
          checkMoreFilterData[index].propertyValues.map(item => {
            checkKeys.push(item.value)
          })
          this.$refs[`morefilter${index}`][0].checkAutoMethods(checkKeys)
        }
      })
    },
    lookTable () {
      EventBusModel.$emit('lookTable')
    },
    // 清除过滤器
    clearFilter () {
      this.clearDataFilter()
      EventBusModel.$emit('clearTableData')
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/css/model.less";

.filter-page {
  .chekButton {
    color: #3ec1ec;
  }
  .pointer {
    cursor: pointer;
  }

  .filter-page-content {
    display: flex;

    /deep/.el-checkbox {
      color: @fontColor;
    }

    /deep/.el-color-picker__trigger {
      padding: 0;
      border: none;
      border-radius: 50%;
      overflow: hidden;
    }

    /deep/.el-tree {
      background-color: @backgroundColor !important;
      color: @fontColor;
    }

    /deep/.el-tree-node__content:hover {
      background-color: @backgroundColor !important;
      color: @fontColor;
    }

    /deep/.el-tree-node__content {
      background-color: @backgroundColor;
      color: @fontColor;
    }

    /deep/.el-color-picker__color {
      background-image: url(../../../assets/modelColor.png);
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: 2px 2px;
    }

    /deep/.el-color-picker__color-inner {
      background-color: transparent !important;
    }

    /deep/.el-icon-arrow-down:before {
      content: "";
    }

    /deep/.el-color-picker__color {
      border-radius: 50%;
      border: none;
    }

    /deep/.el-color-picker__icon {
      width: 24px;
      height: 24px;
    }

    /deep/.el-icon-close:before {
      content: "";
    }

    .packup-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 20px 0;
      background-color: @backgroundColor;
      color: @fontColor;
      font-weight: bold;
      font-size: 30px;
      height: 908px;
      overflow: hidden;
      transition: 0.5s;
      transition-timing-function: linear;
      border-right: 1px solid @borderColor;
      .head-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .iconfont {
          font-size: 26px;
        }
        .icon-caidan2 {
          font-size: 20px;
        }
        p:nth-child(1) {
          margin-bottom: 20px;
        }
      }
    }

    .filter-content {
      position: relative;
      display: flex;
      background-color: @backgroundColor;
      color: @fontColor;
      height: 908px;
      overflow: hidden;
      transition: 0.5s;
      transition-timing-function: linear;

      .left-icon {
        position: absolute;
        left: 16px;
        top: 51%;
        transform: translate(-50%, 0%);
        font-size: 33px;
      }

      .left {
        width: 300px;
        border-right: 1px solid @borderColor;

        .head,
        .title {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: bold;
          box-sizing: border-box;
          padding: 15px 10px;
          border-bottom: 1px solid @borderColor;
        }

        .title {
          padding: 9px 10px;

          .title-left {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .select {
          .select-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            box-sizing: border-box;
            padding: 11px 10px;
            border-bottom: 1px solid @borderColor;
            background-color: @backgroundTitle;

            .select-right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 30px;
            }
          }

          .treeNode {
            display: flex;
            justify-content: space-between;
            width: 90%;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;

            .label-box {
              width: 160px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .selectOne {
          .select-content {
            height: 270px;
            overflow-y: auto;
          }
        }

        .selectTwo {
          .select-title {
            border-top: 1px solid @borderColor;
          }

          .select-content {
            height: 200px;
            overflow-y: auto;
            // background-color: #000088;
          }
        }

        .selectThree {
          .select-title {
            border-top: 1px solid @borderColor;
          }

          .select-content {
            height: 200px;
            overflow-y: auto;
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          padding: 7px 18px;

          p {
            color: #02a7f0;
          }

          // background-color: #000066;
        }
      }

      .right {
        width: 300px;
        border-right: 1px solid @borderColor;
        overflow: hidden;
        transition: 0.5s;
        transition-timing-function: linear;

        /* .element::-webkit-scrollbar { width: 0 !important }
					 */
        .element {
          overflow: -moz-scrollbars-none;
        }

        .head {
          width: 300px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          letter-spacing: 3px;
          font-weight: bold;
          box-sizing: border-box;
          padding: 15px 10px;
          // border-bottom: 1px solid #d9d9d9;
        }

        .morefilter-content {
          height: 850px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}
</style>
