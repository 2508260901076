<template>
  <div class="model-page">
	  <Model/>
  </div>
</template>

<script>
import Model from '@/views/bimmodel/model/Index.vue'
export default {
  name: 'ModelIndex',
  components: { Model },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
	.model-page {
		height: 908px;
	}
</style>
