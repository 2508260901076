<!-- 弹窗组件 -->
<template>
	<div class="page-container">
		<!-- 弹窗弹窗弹窗 -->
		<el-dialog :title="type==1?'添加属性':type==0?'预览数据':'导出Revit属性'" :visible.sync="dialogVisible" width="30%">
			<template v-if="type == 1">
				<div class="addAtribute">
					<div class="check-container">
						<div class="search-box">
							<el-input placeholder="请输入内容" v-model="searchValue" class="input-with-select"
								@keyup.enter.native="searchAdd">
								<el-button slot="append" icon="el-icon-search" @click="searchAdd"></el-button>
							</el-input>
						</div>
						<el-collapse v-model="activeNames" @change="handleChange">
							<el-collapse-item title="Emdata 属性" name="2">
								<div class="table-content">
									<el-tree v-loading="loadingEmedata" :data="showModelData"
										:props="defaultPropsEmdata" show-checkbox node-key="propertyName"
										ref="treeTable" highlight-current
										@check="(node,check) => addChenck(node,check,'E')">
									</el-tree>
								</div>
							</el-collapse-item>
							<el-collapse-item title="Revit 属性" name="3">
								<div class="table-content">
									<el-tree v-loading="loadingRevit" :data="showRevitData" :props="defaultPropsTable"
										show-checkbox node-key="propertyName" ref="treeRevit" highlight-current
										@check="(node,check) => addChenck(node,check,'R')">
									</el-tree>
								</div>
							</el-collapse-item>
						</el-collapse>
						<p style="text-align: right;padding: 30px;">
							<el-button type="primary" @click="dialogVisible = false">取消</el-button>
							<el-button type="danger" @click="submitHandler(1)">确定</el-button>
						</p>
					</div>
					<div class="el-tree-container">
						<p class="marked-words">请拖动元素进行排序</p>
						<div class="el-container">
							<el-tree :data="sortData" node-key="id" :props="sortProps" default-expand-all draggable
								:allow-drop="allowDrop" :allow-drag="allowDrag">
							</el-tree>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="type == 0">
				<div class="search-box">
					<el-input placeholder="请输入内容" v-model="addSearchValue" class="input-with-select"
						@keyup.enter.native="searchMoreAdd">
						<el-button slot="append" icon="el-icon-search" @click="searchMoreAdd"></el-button>
					</el-input>
				</div>
				<p class="marked-words">注意：过滤属性不能在此移除，可以从"更多过滤"中移除</p>
				<el-collapse v-model="activeNames" @change="handleChange">
					<el-collapse-item title="Emdata 属性" name="2">
						<div class="table-content">
							<el-tree v-loading="loadingEmedataAddAttribute" :data="showColumnList"
								:props="defaultCloumn" show-checkbox node-key="propertyName" ref="treeAutoEmadata"
								highlight-current @check-change="checkChange">
							</el-tree>
						</div>
					</el-collapse-item>
					<el-collapse-item title="Revit 属性" name="3">
						<div class="table-content">
							<el-tree v-loading="loadingRevitAddAttribute" :data="showColumnListRevit"
								:props="defaultCloumn" show-checkbox node-key="propertyName" ref="treeAutoRevit"
								highlight-current @check-change="checkChange">
							</el-tree>
						</div>
					</el-collapse-item>
				</el-collapse>
				<p style="text-align: right;padding: 30px;">
					<el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="danger" @click="submitHandler(0)">确定</el-button>
				</p>
			</template>
			<template v-else-if="type == -1">
				<div class="exportRevitAtribute">
					<div class="check-container">
						<div class="search-box">
							<el-input placeholder="请输入内容" v-model="exportRevitSearchValue" class="input-with-select"
								@keyup.enter.native="searchExportRevit">
								<el-button slot="append" icon="el-icon-search" @click="searchExportRevit"></el-button>
							</el-input>
						</div>
						<div class="exportrevit-content">
							<el-tree v-loading="loadingExportRevitData" :data="exportRevitData"
								:props="exportRevitProps" show-checkbox node-key="propertyName"
								ref="treeExportRevitTree" highlight-current @check="addExportRevitData">
							</el-tree>
						</div>
						<p style="text-align: right;padding: 30px;">
							<el-button type="primary" @click="dialogVisible = false">取消</el-button>
							<el-button type="danger" @click="submitHandler(-1)">确定</el-button>
						</p>
					</div>
					<div class="el-tree-container">
						<!-- <p class="marked-words">请拖动元素进行排序</p> -->
						<el-input placeholder="请输入导出的表名" v-model="tableName" class="input-with-select">
						</el-input>
						<div class="el-container">
							<el-tree :data="sortExportRevitData" node-key="id" :props="sortProps" default-expand-all
								draggable :allow-drop="allowDrop" :allow-drag="allowDrag" >
							</el-tree>
						</div>
					</div>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
import EventBusModel from './eventBus/eventBusModel.js'
import {
  Loading
} from 'element-ui'
let loading // 定义loading变量
function startLoading () { // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
export default {
  name: 'AddAttribute',
  props: ['checkedList'],
  data () {
    return {
      dialogVisible: false,
      activeNames: ['1', '2', '3', '4'],
      dataAuto: [{
        id: 123,
        title: '楼层'
      },
      {
        id: 642,
        title: '空间'
      },
      {
        id: 3645,
        title: '构件类型'
      }
      ],
      dataTable: [{
        id: 641,
        title: 'L-厂家'
      },
      {
        id: 264,
        title: 'L-生产日期'
      }
      ],
      dataRevit: [{
        id: 186,
        title: 'L-厂家'
      },
      {
        id: 2,
        title: 'L-生产日期'
      }
      ],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      defaultPropsEmdata: {
        children: 'children',
        label: 'propertyName'
      },
      defaultPropsTable: {
        children: 'children',
        label: 'propertyName'
      },
      defaultCloumn: {
        children: 'children',
        label: 'propertyName'
      },
      sortProps: {
        children: 'children',
        label: 'propertyName'
      },
      exportRevitProps: {
        children: 'children',
        label: 'propertyName'
      },
      searchValue: '',
      columnList: [],
      hiddenColumn: [],
      revitDataList: [],
      addSearchValue: '',
      columnListRevit: [],
      showColumnListRevit: [],
      showColumnList: [],
      showRevitData: [],
      showModelData: [],
      type: true,
      addCheckList: [],
      loadingRevit: true,
      loadingEmedata: true,
      loadingRevitAddAttribute: true,
      loadingEmedataAddAttribute: true,
      loadingExportRevitData: true,
      addEmdataList: [],
      oldAddemdataList: [],
      oldAddRevitList: [],
      addRevitList: [],
      deleteEmdataList: [],
      deleteRevitList: [],
      sortData: [],
      yuansiExportRevitData: [],
      exportRevitData: [],
      sortExportRevitData: [],
      tableName: '',
      exportRevitSearchValue: ''
    }
  },
  created () {},
  mounted () {
    EventBusModel.$on('autoCheckedReview', () => {
      this.autoCheckedReview()
    })
    EventBusModel.$on('addAttribute', (type) => {
      this.openDiaLog(type)
    })
    EventBusModel.$on('exportRevit', () => {
      this.openDiaLog(-1)
    })
  },
  beforeDestroy () {
    EventBusModel.$off()
  },
  computed: {
    ...mapState('bimModel', {
      moreFilterData: 'moreFilterData',
      filterChecked: 'filterChecked',
      modelData: 'modelData',
      reviewData: 'reviewData',
      revitData: 'revitData',
      floorChecked: 'floorChecked',
      spaceChecked: 'spaceChecked',
      familyAndType: 'familyAndType',
      allGuid: 'allGuid'
    })
  },
  methods: {
    ...mapMutations('bimModel', {
      initReviewData: 'initReviewData'
    }),
    addExportRevitData (node, check) {
      let isCheck = check.checkedKeys.includes(node.propertyName)
      if (isCheck) {
        this.sortExportRevitData.push({ propertyName: node.propertyName })
      } else {
        let findIndex = this.sortExportRevitData.findIndex(e => e.propertyName == node.propertyName)
        this.sortExportRevitData.splice(findIndex, 1)
      }
    },
    async autoCheckedReview () {},
    openDiaLog (type) {
      this.loadingRevit = true
      this.loadingEmedata = true
      this.loadingRevitAddAttribute = true
      this.loadingEmedataAddAttribute = true
      this.loadingExportRevitData = true
      this.sortData = []
      if (type < 0) {
        this.type = -1
        this.getExportData()
      } else if (type) {
        this.type = 1
        this.getData()
      } else {
        this.type = 0
        this.getFormData()
      }
      this.dialogVisible = true
    },
    checkFilterData () {
      this.moreFilterData.map(e => {
        if (this.sortData.findIndex(item => item.type == e.type && item.propertyName == e.propertyName) == -1) {
          this.sortData.push({
            propertyName: e.propertyName,
            type: e.type
          })
        }
      })
      let eList = this.showModelData.filter(item => this.sortData.find(e => e.propertyName.split('@')[0] == item
        .propertyName.split('@')[0] && e.type == 'E'))
      setTimeout(() => {
        this.$refs.treeTable.setCheckedNodes(eList)
      }, 10)
      let rList = this.showRevitData.filter(item => this.sortData.find(e => e.propertyName.split('@')[0] == item
        .propertyName.split('@')[0] && e.type == 'R'))
      setTimeout(() => {
        this.$refs.treeRevit.setCheckedNodes(rList)
      })

      this.loadingRevit = false
      this.loadingEmedata = false
    },
    // 导出revit属性初始数据获取
    async getExportData () {
      let rGuidList = this.getGuidList(this.filterChecked, 'R')
      let eGuidList = this.getGuidList(this.filterChecked, 'E')
      let floorCheckGuid = []
      this.floorChecked.map(item => {
        item.floorElementPropertyDtos.map(e => {
          floorCheckGuid.push(e.elementGuid)
        })
      })
      let spaceCheckedGuid = []
      this.spaceChecked.map(item => {
        item.spaceElementPropertyDtos.map(e => {
          spaceCheckedGuid.push(e.elementGuid)
        })
      })
      let familyAndTypeGuid = []
      this.familyAndType.map(item => {
        familyAndTypeGuid.push(...item.elementGuid)
      })
      let guidLists = [...rGuidList, ...eGuidList, ...floorCheckGuid, ...spaceCheckedGuid, ...familyAndTypeGuid
      ]
      let rDataList = []
      if (guidLists.length) {
        rDataList = await this.$axios.post('/api/modeldata/cache_modelpropertynamebyelementguid',
          guidLists)
      }
      let rDataLists = []
      rDataList.map(item => {
        rDataLists.push({
          propertyName: item
        })
      })
      this.yuansiExportRevitData = rDataLists
      this.exportRevitData = JSON.parse(JSON.stringify(rDataLists))
      this.loadingExportRevitData = false
      this.searchExportRevit()
    },
    getGuidList (data, type) {
      let guidList = []
      let list = data.filter(item => item.type == type)
      list.map(e => {
        e.propertyValues.map(a => {
          guidList.push(...a.elementGuid)
        })
      })
      return guidList
    },
    // +属性初始数据获取
    async getFormData () {
      let rGuidList = this.getGuidList(this.filterChecked, 'R')
      let eGuidList = this.getGuidList(this.filterChecked, 'E')
      let floorCheckGuid = []
      this.floorChecked.map(item => {
        item.floorElementPropertyDtos.map(e => {
          floorCheckGuid.push(e.elementGuid)
        })
      })
      let spaceCheckedGuid = []
      this.spaceChecked.map(item => {
        item.spaceElementPropertyDtos.map(e => {
          spaceCheckedGuid.push(e.elementGuid)
        })
      })
      let familyAndTypeGuid = []
      this.familyAndType.map(item => {
        familyAndTypeGuid.push(...item.elementGuid)
      })
      let guidLists = [...rGuidList, ...eGuidList, ...floorCheckGuid, ...spaceCheckedGuid, ...familyAndTypeGuid
      ]
      let rDataList = []
      let eDataList = []
      if (guidLists.length) {
        rDataList = await this.$axios.post('/api/modeldata/cache_modelpropertynamebyelementguid',
          guidLists)
        eDataList = await this.$axios.post('/api/formhandle/getformpropertynamebyelementguid_cache',
          guidLists)
      }
      let rData = []
      let eData = []
      rDataList.map(e => {
        rData.push({
          propertyName: e
        })
      })
      eDataList.map(e => {
        eData.push({
          propertyName: e
        })
      })
      this.columnList = await this.getDisabledList(eData, this.filterChecked, 'E')
      this.columnListRevit = await this.getDisabledList(rData, this.filterChecked, 'R')
      this.searchMoreAdd()
    },
    // 拿出禁用项
    getDisabledList (data, checkData, type) {
      let list = checkData.filter(item => item.type == type)
      data.forEach(item => {
        if (list.find(e => item.propertyName == e.propertyName || item.propertyName == e.propertyName
          .split('@')[0])) {
          item.disabled = true
        }
      })
      return data
    },
    async checkAddAttribute () {
      // 选中更多过滤中的数据
      // let checkNodesMoreFilter = this.filterChecked
      // 需要默认选中table中的表头的数据
      // 选中给的数据1，更多过滤里面选中的数据
      // 2.走更多过滤里面已经添加到表格中给的数据
      // 1. 求emdata数据中更多过滤里面选中的
      // 2. 求revit数据中更多过滤里面选中的
      let checkMoreEmdata = await this.getTypeList('E', this.filterChecked)
      let checkMoreRevit = await this.getTypeList('R', this.filterChecked)
      let checkReviewData = await this.getTypeList('R', this.reviewData)
      let checkEmdataData = await this.getTypeList('E', this.reviewData)
      let revitList = [...checkMoreRevit, ...checkReviewData]
      let emdataList = [...checkMoreEmdata, ...checkEmdataData]
      let checkRevit = []
      let checkEmedata = []
      revitList.map(e => {
        checkRevit.push(e.propertyName.split('@')[0])
      })
      emdataList.map(e => {
        checkEmedata.push(e.propertyName.split('@')[0])
      })
      // 选中事件
      if (checkEmedata.length) {
        setTimeout(() => {
          this.$refs.treeAutoEmadata.setCheckedKeys(checkEmedata)
        }, 100)
      }
      if (checkRevit.length) {
        setTimeout(() => {
          this.$refs.treeAutoRevit.setCheckedKeys(checkRevit)
        }, 100)
      }
    },
    // 求出不同类别的数据
    getTypeList (type, list) {
      let newList = list.filter(item => item.type == type)
      return newList
    },
    // 节点被取消的时候存一个记录
    checkChange (node, check, childCheck) {},
    // 设置中节点变化事件
    async addChenck (node, check, type) {
      let isCheck = check.checkedKeys.includes(node.propertyName)
      if (isCheck) {
        if (type == 'E') {
          this.addEmdataList.push(node.propertyName)
          this.addEmdataList = [...new Set(this.addEmdataList)]
          this.deleteEmdataList = this.deleteEmdataList.filter(item => item != node.propertyName)
        } else {
          this.addRevitList.push(node.propertyName)
          this.addRevitList = [...new Set(this.addRevitList)]
          this.deleteRevitList = this.deleteRevitList.filter(item => item != node.propertyName)
        }
        this.sortData.push({
          type: type,
          propertyName: node.propertyName
        })
      } else {
        if (type == 'E') {
          this.deleteEmdataList.push(node.propertyName)
          this.addEmdataList = this.addEmdataList.filter(item => item != node.propertyName)
        } else {
          this.deleteRevitList.push(node.propertyName)
          this.addRevitList = this.addRevitList.filter(item => item != node.propertyName)
        }
        let findIndex = this.sortData.findIndex(e => e.propertyName == node.propertyName && e.type == type)
        this.sortData.splice(findIndex, 1)
      }
    },
    async getData () {
      let floorCheckGuid = []
      this.floorChecked.map(item => {
        item.floorElementPropertyDtos.map(e => {
          floorCheckGuid.push(e.elementGuid)
        })
      })
      let spaceCheckedGuid = []
      this.spaceChecked.map(item => {
        item.spaceElementPropertyDtos.map(e => {
          spaceCheckedGuid.push(e.elementGuid)
        })
      })
      let familyAndTypeGuid = []
      this.familyAndType.map(item => {
        familyAndTypeGuid.push(...item.elementGuid)
      })
      let guidLists = [...floorCheckGuid, ...spaceCheckedGuid, ...familyAndTypeGuid]
      // 获取revit数据
      let revitData = await this.$axios.post('/api/modeldata/cache_modelpropertynamebyelementguid',
        guidLists)
      // 后去emdata数据
      let modelData = await this.$axios.post('/api/formhandle/getformpropertynamebyelementguid_cache',
        guidLists)
      this.dataTable = []
      this.revitDataList = []
      for (let i = 0; i < modelData.length; i++) {
        if (modelData[i] != '族与类型') {
          this.dataTable.push({
            propertyName: modelData[i],
            id: i
          })
        }
      }
      for (let i = 0; i < revitData.length; i++) {
        if (modelData[i] != '族与类型') {
          this.revitDataList.push({
            propertyName: revitData[i],
            id: i
          })
        }
      }
      this.showModelData = JSON.parse(JSON.stringify(this.dataTable))
      this.showRevitData = JSON.parse(JSON.stringify(this.revitDataList))
      this.checkFilterData()
    },
    handleChange (val) {},
    clearDataFilter () {
      this.addCheckList = []
      this.addEmdataList = []
      this.oldAddemdataList = []
      this.oldAddRevitList = []
      this.addRevitList = []
      this.deleteEmdataList = []
      this.deleteRevitList = []
    },
    async submitHandler (type) {
      // type 为1 就是设置里面的确定
      startLoading()
      try {
        if (type == 1) {
          // 设置里面点确定的时候
          let addListEmdata = this.addEmdataList.filter(e => this.oldAddemdataList.findIndex(p => e ==
								p) ==
							-1)
          let listEmdata = addListEmdata.length ? await this.$axios.post('/api/formhandle/getformproperiesbypropertyname_cache',
            addListEmdata) : []
          listEmdata.forEach(item => item.type = 'E')
          this.oldAddemdataList = JSON.parse(JSON.stringify(this.addEmdataList))
          // revit部分
          let addListRevit = this.addRevitList.filter(e => this.oldAddRevitList.findIndex(p => e == p) ==
							-1)
          let listRevit = addListRevit.length ? await this.$axios.post('/api/modeldata/cache_modelproperiesbyproname',
            addListRevit) : []
          listRevit.forEach(item => item.type = 'R')
          this.oldAddRevitList = JSON.parse(JSON.stringify(this.addRevitList))
          let newMoreFilterDataCopy = this.moreFilterData.concat(listEmdata).concat(listRevit)
          let newMoreFilterData = []
          // 直接针对newMoreFilterDataCopy进行排序
          // 按照sotrData中的顺序进行排序
          this.sortData.map(item => {
            let obj = newMoreFilterDataCopy.find(e => e.type == item.type && e.propertyName ==
								item.propertyName)
            if (obj) {
              newMoreFilterData.push(obj)
            }
          })
          newMoreFilterData.forEach(item => {
            item.propertyValues.forEach(e => {
              if (e.value == '') {
                e.value = '(空)'
                e.valueCount[0] = '(空)'
              }
            })
          })
          // 传递一下确定事件用$Emit
          EventBusModel.$emit('initFilterYansiData', newMoreFilterData, this.deleteEmdataList, this
            .deleteRevitList)
          this.dialogVisible = false
          // 在更多过滤中点击确定的时候
        } else if (type == 0) {
          let data1 = this.$refs.treeAutoEmadata.getCheckedNodes()
          let data2 = this.$refs.treeAutoRevit.getCheckedNodes()
          data1 = data1.filter(item => {
            return !this.filterChecked.find(item2 => item2.propertyName == item.propertyName)
          })
          data2 = data2.filter(item => {
            return !this.filterChecked.find(item2 => item2.propertyName == item.propertyName)
          })
          let addListEmdata = []
          data1.map(e => {
            addListEmdata.push(e.propertyName)
          })
          let addListRevit = []
          data2.map(e => {
            addListRevit.push(e.propertyName)
          })
          let dataEmdata = await this.$axios.post('/api/formhandle/getformproperiesbypropertyname_cache',
            addListEmdata)
          let dataRevit = await this.$axios.post('/api/modeldata/cache_modelproperiesbyproname',
            addListRevit)
          dataEmdata.forEach(item => {
            item.type = 'E'
          })
          dataRevit.forEach(item => {
            item.type = 'R'
          })
          let list = [...dataEmdata, ...dataRevit]
          this.initReviewData(list)
          this.dialogVisible = false
          this.lookTable()
        } else if (type == -1) {
          if (this.tableName == '') {
            this.$message.warning('表名不能为空')
            loading.close()
            return
          }
          let nameList = []
          let tableHeaderName = ['guid', 'elementId', '楼层']
          let tableData = []
          let tableHeaderInfo = []
          this.sortExportRevitData.map(e => {
            nameList.push(e.propertyName)
          })
          let dataRevit = await this.$axios.post('/api/modeldata/cache_modelproperiesbyproname',
            nameList)
          dataRevit.map(item => {
            tableHeaderName.push(item.propertyName)
          })
          let {
            specialCode_RevitDefaultParameters
          } = await this.$axios.get('/api/formhandle/getspecialcode')
          let tableDataObj = {}
          this.allGuid.map(e => {
            tableDataObj[e.guid] = {
              guid: e.guid,
              elementId: e.elementId
            }
          })
          dataRevit.map(e => {
            for (let key in tableDataObj) {
              let value = e.propertyValues.find(val => val.elementGuid.find(guid => guid ==
									key))
              let val = ''
              if (value) {
                val = value.value
              } else {
                val = ''
              }
              tableDataObj[key][e.propertyName] = val
            }
          })
          Object.values(tableDataObj).map(e => {
            let tableDataOne = []
            tableHeaderName.map(item => {
              tableDataOne.push(e[item])
            })
            tableData.push(tableDataOne)
          })
          tableHeaderName[0] = 'ElementGUID'
          tableHeaderName[1] = 'ElementID'
          tableHeaderName.map(e => {
            tableHeaderInfo.push([e, specialCode_RevitDefaultParameters])
          })
          let tableColNum = tableHeaderName.length
          let tableRowNum = tableData.length
          let data = {
            tableMeta: {
              tableName: this.tableName,
              tableHeaderName: tableHeaderName,
              tableHeaderInfo: tableHeaderInfo,
              tableColNum: tableColNum,
              tableRowNum: tableRowNum
            },
            tableData: tableData,
            floorList: []
          }
          let result = await this.$axios.post('/api/formhandle/exportform', data)
          this.$message.success(result)
          this.sortExportRevitData = []
          this.tableName = ''
          this.dialogVisible = false
        }
        loading.close()
      } catch (e) {
        loading.close()
      }
    },
    open () {},
    search (original, val) {
      // 模糊匹配
      var reg = new RegExp(val)
      return reg.test(original)
    },
    // 添加属性中的搜索
    searchAdd () {
      if (this.dataTable && this.dataTable.length) {
        this.showModelData = this.dataTable.filter(item => {
          return this.search(item.propertyName, this.searchValue)
        })
        this.loadingEmedata = false
      } else {
        this.showModelData = []
        this.loadingEmedata = true
      }
      if (this.revitDataList && this.revitDataList.length) {
        this.showRevitData = this.revitDataList.filter(item => {
          return this.search(item.propertyName, this.searchValue)
        })
        this.loadingRevit = false
      } else {
        this.showRevitData = []
        this.loadingRevit = true
      }
      // 每次搜索之后都去更多过滤里面拿一下数据把该选中的选中
      this.checkFilterData()
    },
    // +属性中的搜索
    searchMoreAdd () {
      this.showColumnListRevit = []
      this.showColumnList = []
      if (!this.addSearchValue) {
        this.showColumnListRevit = JSON.parse(JSON.stringify(this.columnListRevit))
        this.showColumnList = JSON.parse(JSON.stringify(this.columnList))
      } else {
        this.showColumnListRevit = this.columnListRevit.filter(item => {
          return this.search(item.propertyName, this.addSearchValue)
        })
        this.showColumnList = this.columnList.filter(item => {
          return this.search(item.propertyName, this.addSearchValue)
        })
      }
      this.loadingRevitAddAttribute = false
      this.loadingEmedataAddAttribute = false
      this.$nextTick(() => {
        this.checkAddAttribute()
      })
    },
    // 导出revit中的搜索
    searchExportRevit () {
      this.exportRevitData = []
      this.exportRevitData = this.yuansiExportRevitData.filter(item => {
        return this.search(item.propertyName, this.exportRevitSearchValue)
      })
      // // 反选
      this.$nextTick(() => {
        this.$refs.treeExportRevitTree.setCheckedNodes(this.sortExportRevitData)
      })
    },
    lookTable () {
      EventBusModel.$emit('lookTable')
    },
    allowDrop (draggingNode, dropNode, type) {
      if (type != 'inner') {
        return true
      }
    },
    allowDrag (draggingNode) {
      return true
    }
  }
}
</script>

<style scoped lang="less">
	@import '../../../assets/css/model.less';

	.page-container {
		/deep/.el-dialog__title {
			color: @fontColor;
		}

		/deep/.el-input__inner {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-dialog,
		.el-pager {
			background-color: @backgroundColor;
		}

		/deep/.el-collapse {
			border-color: @borderColor;
		}

		/deep/.el-collapse-item__wrap {
			border-color: @borderColor;
		}

		/deep/.el-collapse-item__header {
			border-color: @borderColor;
		}

		/deep/.el-input-group__append,
		.el-input-group__prepend {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-input__inner:focus {
			border-color: @borderColor;
		}

		/deep/.el-dialog__body {
			padding: 0 20px;
		}

		/deep/.el-collapse-item__header {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-button--primary {
			background-color: @backgroundColor;
			color: @fontColor;
			border-color: @borderColor;
		}

		/deep/.el-button--danger {
			background-color: rgba(56, 171, 223, 1.0);
			color: @fontColor;
			border-color: transparent;
			border: none;
		}

		/deep/.el-button--close {
			background-color: #475065;
			border: none;
			color: @fontColor;
		}

		/deep/.el-tree-node__content {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
			background-color: rgba(230, 230, 230, 0.9);
		}

		/deep/.el-collapse-item__wrap {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-collapse-item__header {
			border-top: 1px solid @borderColor;
			font-size: 16px;
			height: 30px;
			line-height: 30px;
		}

		/deep/.el-collapse-item__header.is-active {
			border-bottom: 1px solid @borderColor;
		}

		/deep/.el-collapse-item__content {
			padding-bottom: 0;
			padding: 10px 0;
		}

		/deep/.el-icon-arrow-right:before {
			content: '\e791'
		}

		/deep/.el-collapse {
			border-bottom: 1px solid @borderColor;
			background-color: @backgroundColor  !important;
		}

		/deep/.el-tree {
			color: @fontColor;
			background-color: @backgroundColor  !important;
		}

		/deep/.el-tree__empty-text {
			color: @fontColor;
		}

		.search-box {
			padding: 0 0 20px 0;
		}

		.marked-words {
			color: @fontColor;
			text-align: left;
			padding-left: 0px;
			padding-bottom: 10px;
		}

		.table-content {
			// max-height: 220px;
			// min-height: 220px;
			height: 220px;
			overflow-y: auto;
		}

		.exportrevit-content {
			height: 450px;
			overflow-y: auto;
		}

		.tree-box {
			max-height: 450px;
			overflow-y: auto;
		}

		.addAtribute,
		.exportRevitAtribute {
			display: flex;
			justify-content: space-between;

			.check-container {
				width: 60%;
			}

			.el-tree-container {
				width: 35%;

				.el-container {
					max-height: 580px;
					overflow-y: auto;

					/deep/.el-tree {
						width: 300px;
					}

					/deep/.el-tree-node__content {
						margin: 1px 2px;
						background-color: #eaeaea;
					}
				}
			}
		}

		.exportRevitAtribute {
			.el-container {
				margin-top: 20px;
				max-height: 450px !important;
			}
		}
	}
</style>
