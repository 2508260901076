<!-- 模型组件 -->
<template>
    <div class="model">
        <!-- <div class="filter_div">
          <div class="floor_filter_div" style="display:flex">
                <div style="width:50%">
                    <el-select v-model="floorSelectValue" multiple placeholder="请选择楼层" @change="floorSelevterOnChange">
                        <el-option v-for="item in fllorSelecter" :key="item.UniqueId" :label="item.Name" :value="item.UniqueId">
                        </el-option>
                    </el-select>
                </div>
                <div style="width:50%">
                    <el-select v-model="roomSelectValue" multiple placeholder="请选择房间">
                        <el-option v-for="item in roomSelecter" :key="item.UniqueId" :disabled="item.disabled" :label="item.Name" :value="item.UniqueId">
                        </el-option>
                    </el-select>

                </div>
            </div>
            <div class="property_filter_div">
                <div>
                    <el-button type="primary" @click="clearFilter">清除过滤</el-button>
                    <el-button type="primary" @click="fliterElemens">过滤构件</el-button>
                </div>
                <div>
                    <el-select style="width:30%" v-model="propertySelectValue" placeholder="请选择属性">
                        <el-option v-for="item in propertyKeyList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <el-select style="width:30%" v-model="symbolSelectValue" placeholder="请选择比较方式">
                        <el-option v-for="item in symbolList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    <el-input style="width:30%" type="text" v-model="inputValue" placeholder="请输入值" />
                </div>
                <div>
                    <el-button type="primary" @click="fliterElemensByProperty">属性过滤</el-button>
                </div>
            </div>
            <div class="model_tree_div">
                <el-tree ref="model_category_tree" :data="categoryTree" show-checkbox node-key="dbid" :props="categoryTreeProps">
                </el-tree>
            </div>

        </div> -->
        <div class="page-content">
            <div class="viewer3d" id="viewer3d"></div>
        </div>
    </div>
</template>

<script>
import {
  mapMutations
} from 'vuex'
import { injectCSS, injectJsScript } from '../../../utils/InjectJsCss'
import EventBusModel from './eventBus/eventBusModel.js'
var bimEngine
// var UniversalPanel;
var idMapping = null
var roomElements = []
var elementProperties = null
export default {
  name: 'ModelContent',
  props: ['showPropertyFlag'],
  data () {
    return {
      floorSelectValue: [],
      roomSelectValue: [],
      categorySelectValue: [],
      propertySelectValue: '',
      symbolSelectValue: '',
      propertyKeyList: [],
      inputValue: '',
      symbolList: [
        '>', '>=', '<', '<=', '='
      ],
      floors: [],
      rooms: [],
      fllorSelecter: [],
      roomSelecter: [],
      categoryTree: [],
      categoryTreeProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  created () {
    let modelInfo = localStorage.getItem('modelInfo')
    if (modelInfo) {
      this.initJsCss(JSON.parse(modelInfo))
    }
  },
  mounted () {
    EventBusModel.$on('selectElement', (elementList) => {
      console.log(elementList)
      this.selectElement(elementList)
    })
  },
  methods: {

    ...mapMutations('bimModel', {
      initCategoryData: 'initCategoryData'
    }),

    /**
        * 改变构件颜色
        * elementId：构件的guid
        * r,g,b,a: 颜色：0~1
        */
    changeElementColor (elementList, r, g, b, a) {
      elementList.forEach(element => {
        if (idMapping[element] != undefined) {
          bimEngine.setThemingColor(idMapping[element], new THREE.Vector4(r, g, b, a))
        }
      })
    },
    /**
         * 清除构件的颜色
         */
    clearElementColor (elementList) {
      elementList.forEach(element => {
        if (idMapping[element] != undefined) {
          bimEngine.clearThemingColor(idMapping[element])
        }
      })
    },
    /**
         * 清除所有构件的着色。
         */
    clearAllElementColor () {
      bimEngine.clearThemingColors()
    },

    /**
         * elementList:构件guid数组
         */
    selectElement (elementList) {
      this.fitToView(elementList[0])
      var re = []
      elementList.forEach(element => {
        if (idMapping[element] != undefined) {
          re.push(idMapping[element])
        }
      })
      bimEngine.select(re)
    },

    fitToView (element) {
      console.log(element)
      if (element && idMapping[element] != undefined) {
        bimEngine.fitToView([idMapping[element]])
      }
    },

    /**
         * 清除选择
         */
    clearSelection () {
      bimEngine.clearSelection()
    },
    /**
         * 隔离构件
         */
    isolate (elementList) {
      var re = []
      elementList.forEach(element => {
        if (idMapping[element] != undefined) {
          re.push(idMapping[element])
        }
      })
      bimEngine.isolate(re)
    },
    /**
         * 清除隔离
         */
    clearIsolate () {
      bimEngine.isolate()
    },

    getTreeElementList (nodeId, callback) {
      var result = []
      var instanceTree = bimEngine.model.myData.instanceTree
      if (nodeId instanceof Array) {

      } else {
        nodeId = [nodeId]
      }
      nodeId.forEach(element => {
        instanceTree.enumNodeChildren(element, dbid => {
          if (instanceTree.getChildCount(dbid) == 0) {
            // 最终的子节点是构件
            result.push(dbid)
          }
        }, true)
      })

      bimEngine.model.getExternalIdArray(result, d => {
        var re = []
        if (callback) {
          Object.keys(d).forEach(key => {
            if (d.hasOwnProperty(key)) {
              re.push(key)
            }
          })
          callback(re)
        }
      })
    },

    initSelect () {
      this.$axios.get(`/api/Property/GetLevelList`).then(res => {
        this.floors = res
        this.fllorSelecter = this.floors
      })

      this.$axios.get(`/api/Property/GetSapceList`).then(res => {
        this.rooms = res
        this.roomSelecter = this.rooms
      })

      this.$axios.get(`/api/Property/GetAllSpaceElementList`).then(res => {
        roomElements = res
      })
    },
    floorSelevterOnChange (current) {
      this.rooms.forEach(e => {
        if (this.floorSelectValue.findIndex(n => n == e.LevelUniqueId) == -1) {
          // 房间的楼层标高 不在当前选择的标高中
          e.disabled = true
        } else {
          e.disabled = false
        }
      })
    },
    clearFilter () {
      this.roomSelectValue = []
      this.floorSelectValue = []
      bimEngine.isolate()
    },
    fliterElemens () {
      bimEngine.clearSelection()
      bimEngine.clearThemingColors()
      var result = []
      var rootId = bimEngine.model.myData.instanceTree.getRootId()
      var instanceTree = bimEngine.model.myData.instanceTree

      if (this.roomSelectValue.length > 0) {
        // 选了房间,则在房间内的构件进行过滤
        var elements = []
        this.roomSelectValue.forEach(element => {
          var filter = roomElements.filter(n => { return n.SpaceUniqueId == element })
          elements = elements.concat(elements, filter)
        })
        elements.forEach(e => {
          if (idMapping[e.UniqueId] !== undefined) {
            result.push(idMapping[e.UniqueId])
          }
        })
      } else if (this.floorSelectValue.length > 0) {
        // 未选择房间,选择了楼层
        // 先根据楼层标高过滤
        // 获取所选楼层的标高范围,根据构件的boundingbox进行过滤
        var floors = bimEngine.getExtension('Smart.AEC.LevelsExtension').floorSelector._floors
        let min; let max
        floors.forEach(element => {
          if (this.floorSelectValue.findIndex(n => n == element.guid) > -1) {
            if (min == undefined) {
              min = element.zMin
            } else {
              if (element.zMin < min) {
                min = element.zMin
              }
            }
            if (max == undefined) {
              max = element.zMax
            } else {
              if (element.zMax > max) {
                max = element.zMax
              }
            }
          }
        })
        instanceTree.enumNodeChildren(rootId, dbid => {
          if (instanceTree.getChildCount(dbid) == 0) {
            var temp = new Float32Array(6)
            instanceTree.getNodeBox(dbid, temp)
            var z = (temp[2] + temp[5]) * 0.5
            if (z >= min && z <= max) {
              result.push(dbid)
            }
          }
        }, true)
      } else {
        // 既未选择楼层，也未选择标高，此时全部构件根据 类型 过滤。
      }
      var checked = this.$refs.model_category_tree.getCheckedNodes()
      // 是否 勾选了类型分类
      if (checked.length > 0) {
        var filterResult = []
        if ((this.roomSelectValue.length > 0 || this.floorSelectValue.length > 0) && result.length > 0) {
          // 使用了楼层或房间过滤，此时再根据构件类型过滤
          result.forEach(e => {
            let parentId = instanceTree.getNodeParentId(e)
            if (checked.findIndex(n => n.dbid == parentId) > -1) {
              filterResult.push(e)
            }
          })
        } else {
          // 未使用楼层或房间过滤，只使用构件类型过滤。
          var parentNode = []
          checked.forEach(e => {
            if (e.children.length == 0) {
              parentNode.push(e.dbid)
            }
          })
          parentNode.forEach(e => {
            instanceTree.enumNodeChildren(e, dbid => {
              filterResult.push(dbid)
            }, false)
          })
        }
        bimEngine.isolate(filterResult)
        if (filterResult.length > 0) {
          bimEngine.model.getExternalIdArray(filterResult, d => {
            this.getPropertyKeyList(d)
          })
        } else {
          this.$message({
            message: '未找到符合条件的构件'
          })
        }
      } else {
        bimEngine.isolate(result)
        if (result.length > 0) {
          bimEngine.model.getExternalIdArray(result, d => {
            this.getPropertyKeyList(d)
          })
        } else {
          this.$message({
            message: '未找到符合条件的构件'
          })
        }
      }
    },
    fliterElemensByProperty () {
      bimEngine.clearThemingColors()
      var isolate = bimEngine.getIsolatedNodes()
      if (isolate.length > 0) {
        bimEngine.model.getExternalIdArray(isolate, d => {
          this.getPropertyKeyList(d)
          var data = new FormData()
          var e = []
          Object.keys(d).forEach(key => {
            if (d.hasOwnProperty(key)) {
              e.push(key)
            }
          })
          data.append('Elements', e.join(','))
          data.append('PropertyName', this.propertySelectValue)
          data.append('Symbol', this.symbolSelectValue)
          data.append('Value', this.inputValue)
          this.$axios.post(`/api/Property/GetFilterElementsByProperty`, data).then(res => {
            // var finalResult = [];
            res.forEach(element => {
              if (d[element] != undefined) {
                // finalResult.push(d[element]);
                // bimEngine.select(finalResult);
                bimEngine.setThemingColor(d[element], new THREE.Vector4(1, 1, 0, 1))
              }
            })
          })
        })
      }
    },
    getPropertyKeyList (elements) {
      var data = new FormData()
      var e = []
      Object.keys(elements).forEach(key => {
        if (elements.hasOwnProperty(key)) {
          e.push(key)
        }
      })
      data.append('Elements', e.join(','))
      this.$axios.post(`/api/Property/GetFilterPropertyKeyList`, data).then(res => {
        this.propertyKeyList = res
      })
    },
    initJsCss (modelInfo) {
      injectCSS('/SmartViewer/style.min.css')
      injectJsScript('/SmartViewer/viewer3D.min.js', () => {
        // import('../../utils/UniversalPanel').then(fn=>{
        //     UniversalPanel = fn.UniversalPanel;
        // });
        this.$axios.get('/api/model/GetModelAppsettingInfo').then(res => {
          this.initBIMEngine(modelInfo, res)
        })
      }, error => {
        console.error('sdk加载失败')
      })
    },
    initBIMEngine (modelInfo, serverConfig) {
      var config = {
        https: serverConfig.https === 'true', // 模型后台服务是否使用https连接
        ip: serverConfig.ip, // 模型后台服务的ip地址
        port: serverConfig.port, // 模型后台服务的端口号
        userKey: modelInfo.userKey, // 需要加载模型的用户key
        projectId: modelInfo.projectGuid, // 需要加载模型的项目key
        domId: 'viewer3d'
      }
      var options = {
        config3d: {
          // viewCubeSize:"100px",
          // defaultLightPreset3d:1,
          // hideSearch:true,    // 控制结构树面板是否显示搜索框
          addFooter: true, // 控制打开的面板页是否有footer可拖动大小的组件(适用于PC,手机和pad页面由于屏幕较小，默认存在footer组件);
          // width:'318px',      // 控制打开的面板页面的默认宽度
          // height:'610px',     // 控制打开的面板页面的默认高度
          extensions: [
            'Smart.Measure',
            'Smart.Section',
            'Smart.Viewing.MarkupsCore',
            'Smart.Viewing.MarkupsGui',
            'Smart.AEC.LevelsExtension'
            // "Smart.LevelGrid"
          ],
          // measurePositionTool: true,
          disabledExtensions: { navTools: true, fusionOrbit: true }
        },
        applyRefPoint: true,
        applyScaling: { to: 'mm' },
        createWireframe: true,
        isAEC: true,
        useConsolidation: true,
        consolidationMemoryLimit: 100 * 1024 * 1024 // 256MB - Optional, defaults to 100MB
      }
      var viewerElement = document.getElementById(config.domId)
      options = Object.assign(options, config)
      bimEngine = new Smart.Viewing.GuiViewer3D(viewerElement, options.config3d)
      window.bimEngine = bimEngine
      this.bimEngineCallback()
      Smart.Viewing.Initializer(options, (data) => {
        bimEngine.start()

        function onSuccess (res) {
          bimEngine.loadExtension('Smart.AEC.LevelsExtension').then(level => {
            level.setAecModelData(res.aecModelData, res.placementTf)
            level.floorSelector._selectFloor()
            bimEngine.loadExtension('Smart.AEC.Minimap3DExtension').then(minmap => {
              minmap.mapWidgetHeader.style.display = 'flex'
            })
          })
        }

        var selectedModel = data[0].data.find(n => n.ModelId == modelInfo.modelGuid)
        if (selectedModel) {
          bimEngine.loadModel(selectedModel.ModelView[0].LoadUrl, options, m => {
            if (m.is3d()) {
              m.getExternalIdMapping(mapping => {
                idMapping = mapping
              })
              // bimEngine.loadExtension("Smart.LevelGrid");
              m.downloadAECModelData(onSuccess)
              bimEngine.loadExtension('Smart.AEC.LevelsExtension')
            }
          })
        } else {
          console.error('未找到模型')
        }
      })
    },
    // showPropertyPanel(){
    // 	let flag = bimEngine.getPropertyPanel().isVisible()  //这个能获取 属性窗口时否显示了。
    // 	this.$emit('changeCheckProperty', flag)
    // 	bimEngine.getPropertyPanel().setVisible(!flag)  //这个方法传的参数 能控制属性窗口的显示/隐藏
    // },

    bimEngineCallback () {
      bimEngine.addEventListener(Smart.Viewing.RENDER_FIRST_PIXEL, data => {

      })
      function initCategoryTreeData (rootID, instanceTree, data, exidMapping, treeData) {
        instanceTree.enumNodeChildren(rootID, id => {
          if (instanceTree.getChildCount(id) > 0) {
            var item = {
              dbid: id,
              label: instanceTree.getNodeName(id),
              children: [],
              elementList: [],
              count: 0
            }
            initCategoryTreeData(id, instanceTree, item, exidMapping, treeData)
            data.count += item.count
            item.elementCount = [item.label, item.count]
            data.children.push(item)
          } else {
            let parentId = instanceTree.getNodeParentId(data.dbid)
            let parentName = instanceTree.getNodeName(parentId)
            data.category = parentName + ':' + data.label

            let nodeName = instanceTree.getNodeName(id)
            let last01 = nodeName.lastIndexOf('[')
            let last02 = nodeName.lastIndexOf(']')
            let elemtntId = ''
            if (last01 > -1 && last02 > -1) {
              elemtntId = nodeName.substring(last01 + 1, last02)
            }
            let guid = ''
            if (exidMapping[id]) {
              guid = exidMapping[id]
            }

            // 最终的叶子节点
            if (data.label == '常规模型') {
              // 对于空间构件的特殊处理
              let nodeNameWithoutElementId = nodeName.substring(0, last01)
              // 空间构件在常规模型下
              var spaceIndex = treeData.children.findIndex(n => n.label == '空间')
              if (spaceIndex < 0) {
                let spaceItem = {
                  dbid: id,
                  label: '空间',
                  children: [],
                  elementCount: ['空间', 1],
                  elementList: [],
                  count: 1
                }
                let item = {
                  dbid: id,
                  label: nodeNameWithoutElementId,
                  category: '空间' + ':' + nodeNameWithoutElementId,
                  children: [],
                  elementCount: [nodeNameWithoutElementId, 1],
                  elementList: [{
                    elementGuid: guid,
                    elementId: elemtntId
                  }],
                  count: 1
                }
                spaceItem.children.push(item)
                treeData.children.unshift(spaceItem)
              } else {
                let item = {
                  dbid: id,
                  label: nodeNameWithoutElementId,
                  category: '空间' + ':' + nodeNameWithoutElementId,
                  children: [],
                  elementCount: [nodeNameWithoutElementId, 1],
                  elementList: [{
                    elementGuid: guid,
                    elementId: elemtntId
                  }],
                  count: 1
                }
                treeData.children[spaceIndex].count++
                treeData.children[spaceIndex].elementCount[1]++
                treeData.children[spaceIndex].children.push(item)
              }
            } else {
              data.count++
              data.elementList.push({
                elementGuid: guid,
                elementId: elemtntId
              })
            }
          }
        })
      }
      bimEngine.addEventListener(Smart.Viewing.OBJECT_TREE_CREATED_EVENT, data => {
        var model = data.model
        var instanceTree = model.myData.instanceTree
        var rootId = instanceTree.getRootId()

        model.getExternalIdMapping(exidMapping => {
          let idextMapping = {}
          Object.keys(exidMapping).forEach(key => {
            if (exidMapping.hasOwnProperty(key)) {
              idextMapping[exidMapping[key]] = key
            }
          })
          var treeData = {
            dbid: rootId,
            label: instanceTree.getNodeName(rootId),
            children: [],
            elementList: [],
            count: 0
          }
          initCategoryTreeData(rootId, instanceTree, treeData, idextMapping, treeData)
          console.log(treeData)
          this.categoryTree = treeData.children// 左侧构件类型数据
          this.initCategoryData(this.categoryTree)
        })
      })
      bimEngine.addEventListener(Smart.Viewing.GEOMETRY_LOADED_EVENT, data => {
        // bimEngine.getPropertyPanel().addVisibilityListener(visible=>{
        // 	this.$emit('changeCheckProperty',!visible)
        // })
      })
      bimEngine.addEventListener(Smart.Viewing.TOOLBAR_CREATED_EVENT, data => {
        bimEngine.foldUnfoldTools.getControl('unfold-toolbar').onClick() // 默认展开工具条
        bimEngine.foldUnfoldTools.container.style.display = 'none' // 隐藏折叠按钮
      })

      // 点击构件，查询属性信息
      bimEngine.addEventListener(Smart.Viewing.SELECTION_CHANGED_EVENT, evt => {
        // 判断面板是否显示出来了，面板显示出来了才请求构件属性数据
        if (this.showPropertyFlag) {
          if (evt.dbIdArray && evt.dbIdArray.length > 0) {
            // 选中了构件
            let dbid = evt.dbIdArray[0]
            bimEngine.model.getExternalId(dbid, elementId => {
              // 根据 elementId  获取构件属性
              this.$emit('getInfo', elementId)
            })
          } else {
            // 点击空白处，清空数据
            this.$emit('clearInfo')
          }
        }
      })
    },

    // 获取选中构件
    getSelection () {
      let elementList = bimEngine.getSelection()
      if (elementList.length > 0) {
        // 说明有选中，获取第一个
        let dbid = elementList[0]
        bimEngine.model.getExternalId(dbid, elementId => {
          // 根据 elementId  获取构件属性
          this.$emit('getInfo', elementId)
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.model {
    .page-content {
        width: 100%;
        height: 908px;
        .viewer3d {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
