<template>
	<div class="page-container">
		<div class="component-info-container" id="component-info-container" v-if="showPropertyFlag">
			<div class="info-header" @mousedown="mouserDown" >
				<span>模型属性</span>
				<span class="el-icon-close close-btn" @click="close"></span>
			</div>
			<div class="info-container" v-loading="loadingList">
				<ul class="list-container" v-for="item of listData" :key="item.source">
					<li class="header">
						{{item.source}}
					</li>
					<li class="container" v-for="(child,index) of item.elementPropertyNVs" :key="index">
						<span>{{child.propertyName}}</span>
						<span>{{child.perprotyValue}}</span>
					</li>
					<div class="noChild" v-if="!item.elementPropertyNVs.length">
						暂无属性
					</div>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'ComponentInfo',
  props: ['showPropertyFlag'],
  data () {
    return {
      selectElement: null,
      listData: [],
      loadingList: false
    }
  },
  methods: {
    mouserDown () {
      this.selectElement = document.getElementById('component-info-container')
      var div1 = this.selectElement
      this.selectElement.style.cursor = 'move'
      this.isDowm = true
      var distanceX = event.clientX - this.selectElement.offsetLeft
      var distanceY = event.clientY - this.selectElement.offsetTop
      document.onmousemove = function (ev) {
        var oevent = ev || event
        let left = oevent.clientX - distanceX
        let top = oevent.clientY - distanceY
        if (left < 1620 && left > 0) {
          div1.style.left = left + 'px'
        }
        if (top > 0 && top < 410) {
          div1.style.top = top + 'px'
        }
      }
      document.onmouseup = function () {
        document.onmousemove = null
        document.onmouseup = null
        div1.style.cursor = 'default'
      }
    },
    close () {
      this.$emit('showProperty')
    },
    getInfo (guid) {
      this.loadingList = true
      this.$axios.get(`/api/modeldata/getmodelproperybyelementid?elementGuid=${guid}`).then(res => {
        // 倒叙是为了将emdata的数组放上面，revit的数据放下面
        this.listData = res.reverse()
      })
      this.loadingList = false
    }
  }
}
</script>

<style lang="less" scoped>
	@import '../../../assets/css/model.less';

	.page-container {
		.component-info-container {
			position: absolute;
			border: 1px solid @borderColor;
			background-color: @backgroundColor;
			width: 300px;
			height: 480px;
			border-radius: 5px;
			z-index: 999;
			right: 5px;
			top: 5px;
			overflow-y: auto;
			padding-bottom: 20px;
			.info-header {
				display: flex;
				cursor: move;
				justify-content: space-between;
				padding: 10px 20px;
				border-bottom: 1px solid @borderColor;
				.close-btn{
					cursor: pointer;
				}
			}
			.info-container{
				width: 90%;
				// border: 1px solid @borderColor;
				margin: auto;
				min-height: 443px;
				.list-container{
					border: 1px solid @borderColor;
					margin-top: 20px;
					border-radius: 3px;
					.header{
						height: 40px;
						line-height: 40px;
						border-bottom: 1px solid @borderColor;
					}
					.container{
						display: flex;
						justify-content: space-between;
						height: 30px;
						border-bottom: 1px solid @borderColor;
						span{

							white-space: nowrap;  //强制不换行
							text-overflow:ellipsis; //省略号显示
							overflow:hidden; //超出隐藏
						}
						span:nth-child(1){
							height: 100%;
							width: 90px;
							border-right: 1px solid @borderColor;
							text-align: left;
							line-height: 30px;
							padding-left: 5px;
						}
						span:nth-child(2){
							height: 100%;
							width: 180px;
							// background-color: #000000;
							text-align: left;
							line-height: 30px;
							padding-left: 5px;
						}
					}
					.container:last-child{
						border: none;
					}
					.noChild{
						padding: 20px 0;
					}
				}
			}
		}
	}
</style>
