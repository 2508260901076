<!-- model模块主组件 -->
<template>
	<div class="model-page-content">
		<!-- 过滤器 -->
		<div class="left">
			<FilterComponent ref="filterComponent" @changeTableWidth="changeTableWidth" @showTable="showTable" @showProperty="showProperty"/>
		</div>
		<div class="bottom" v-show="tableShow" :style="`left:${left}px`">
			<Statement ref="statement" :tableData="tableData" :width="tableWidth" @addAttribute="addAttribute"
				@clearData="clearData" @checkMoreFilterMethods="checkMoreFilterMethods" />
		</div>
		<!-- <div v-if="isAddDia"> -->
		<AddAttribute ref="addAttributeComponent" :type="type" :checkedList="checkedList" />
		<!-- </div> -->
		<!-- 报表 -->
		<!-- 放置模型的组件 -->
		<div class="model-div">
			<ModelContent ref="modelContentComponent" :showPropertyFlag="showPropertyFlag" @getInfo="getInfo" @clearInfo="clearInfo"/>
		</div>
		<!-- 弹出的信息框 -->
		<ComponentInfo :showPropertyFlag="showPropertyFlag" @showProperty="showProperty" ref="componentInfo"/>
	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
import FilterComponent from './FilterComponent.vue'
// import MoreFilter from './MoreFilter.vue'
import Statement from './Statement.vue'
import ModelContent from './ModelContent.vue'
import AddAttribute from './AddAttribute.vue'
import EventBusModel from './eventBus/eventBusModel.js'
import ComponentInfo from '../components/ComponentInfo.vue'
export default {
  name: 'Index',
  components: {
    FilterComponent,
    Statement,
    ModelContent,
    AddAttribute,
    ComponentInfo
  },
  data () {
    return {
      tableWidth: 1260,
      tableShow: true,
      left: 660,
      type: -1,
      checkedList: [],
      tableData: [],
      showGuid: [],
      loading: false,
      color: 'rgba(55, 190, 254, 1.0)',
      showPropertyFlag: false
    }
  },
  mounted () {
    EventBusModel.$on('checkedObj', (data) => {
      this.checkedObj(data)
    })
    EventBusModel.$on('checkComponentType', (data) => {
      this.checkComponentType(data)
    }),
    EventBusModel.$on('unCheckComponentType', (data) => {
      this.unCheckComponentType(data)
    })
    EventBusModel.$on('clearTableData', () => {
      this.initModel()
    })
  },
  beforeDestroy () {
    EventBusModel.$off()
  },
  created () {
    this.cacheData()
  },
  computed: {
    ...mapState('bimModel', {
      floorChecked: 'floorChecked',
      spaceChecked: 'spaceChecked',
      filterChecked: 'filterChecked',
      familyAndType: 'familyAndType'
    })
  },
  watch: {
    floorChecked (val, oldVal) {
      this.filterRevitData()
    },
    spaceChecked (val, oldVal) {
      this.filterRevitData()
    }
  },
  destroyed () {
    // 清楚缓存
    this.$axios.get('/api/formhandle/removeformproperiescache')
    this.$axios.get('/api/modeldata/removemodelpropertycache')
    this.initCacheState(false)
  },
  methods: {
    ...mapMutations('bimModel', {
      initTableData: 'initTableData',
      initModelData: 'initModelData',
      initRevitData: 'initRevitData',
      initCacheState: 'initCacheState'
    }),
    async filterRevitData () {
      let floorIds = []
      let spaceIds = []
      let floorList = []
      let spaceList = []
      if (floorIds.length) {
        this.floorChecked.map(e => {
          floorList.push(...e.floorElementPropertyDtos)
        })
      }
      if (spaceIds.length) {
        this.spaceChecked.map(e => {
          spaceList.push(...e.spaceElementPropertyDtos)
        })
      }
      let guid = []
      floorList.map(e => {
        guid.push(e.elementGuid)
      })
      spaceList.map(e => {
        guid.push(e.elementGuid)
      })
      if (!guid.length) {
        this.initRevitData(this.revitDataList)
        return
      }
      let copyList = JSON.parse(JSON.stringify(this.revitDataList))
      const list = copyList.filter(e => {
        let arr = []
        e.propertyValues.map(a => arr.push(...a.elementGuid))
        arr = [...new Set(arr)]
        let arr2 = [...arr, ...guid]
        return [...new Set(arr2)].length != arr2.length
      })
      this.initRevitData(list)
    },
    // 缓存
    async cacheData () {
      let flag1 = false
      // let flag2 = false
      await this.$axios.get('/api/formhandle/getallformproperiesbyelementguid_cache').then(res => {
        flag1 = true
      })
      // await this.$axios.get('/api/modeldata/cache_modelproperiesbymodeldbpath').then(res => {
      // 	flag2 = true
      // })
      if (flag1) {
        this.initCacheState(true)
      }
    },
    showTable () {
      this.tableShow = !this.tableShow
    },
    changeTableWidth (tableWidth, left) {
      this.left = left
      this.tableWidth = tableWidth
    },
    changeCheckProperty (val) {
      this.$refs.filterComponent.isCheckProperty = !val
    },
    addAttribute (type) {
      this.type = type
      this.$refs.addAttributeComponent.openDiaLog()
    },
    addFilterData (data) {
      const guidList = data[0].propertyValues
      var datas = data.slice(2)
      const len = this.$refs.filterComponent.rightFliter.length
      var addArr = []
      datas.forEach(item => {
        const list = [...new Set(item.propertyValues)]
        const addObjCopy = {}
        addObjCopy.title = item.propertyName
        addObjCopy.filterList = []
        const addObj = {}
        addObj.title = item.propertyName
        addObj.filterList = []
        for (var ind in item.propertyValues) {
          addObjCopy.filterList.push({
            id: `${len}${ind}` - 0,
            label: item.propertyValues[ind],
            guid: guidList[ind]
          })
        }
        for (var ind in list) {
          const guiList = []
          let num = 0
          for (var item of addObjCopy.filterList) {
            if (item.label == list[ind]) {
              guiList.push(item.guid)
              num++
            }
          }
          addObj.filterList.push({
            id: `${len}${ind}` - 0,
            label: [list[ind], num],
            guid: guiList,
            num: `${num}`
          })
        }

        addArr.push(addObj)
      })
      this.$refs.filterComponent.rightFliter.push(...addArr)
    },
    // 选择更多过滤
    checkedObj (data) {
      let color = JSON.parse(JSON.stringify(this.color))
      color = color.split('(')
      color = color[1].split(')')
      color = color[0].split(',')
      this.$refs.modelContentComponent.clearIsolate()
      this.$refs.modelContentComponent.clearElementColor(this.showGuid)
      if (data.length) {
        this.$refs.modelContentComponent.changeElementColor(data, color[0] / 255, color[1] / 255, color[2] /
						255, color[3])
        this.$refs.modelContentComponent.isolate(data)
        this.showGuid = JSON.parse(JSON.stringify(data))
      }
    },
    // 模型初始化
    initModel () {
      this.$refs.modelContentComponent.clearIsolate()
      this.$refs.modelContentComponent.clearElementColor(this.showGuid)
    },
    // 操作构建类型
    async checkComponentType (data, color) {
      this.showGuid = [...this.showGuid, ...data[0].elementGuid]
      this.checkedObj(data[0].elementGuid, color)
    },
    // 构件类型取消选中
    unCheckComponentType (data) {
      this.uncheckedObj(data[0].elementGuid)
    },
    // 清空数据的方法
    clearData () {
      // 这里需要加个控制器控制一下
      if (this.showGuid.length) {
        this.$refs.modelContentComponent.clearElementColor(this.showGuid)
        this.$refs.modelContentComponent.clearIsolate()
        this.showGuid = []
      }
      // 过滤器中的选择项，更多过滤中的数据和选择项，＋属性中的数据
      this.$refs.filterComponent.clearDataFilter()
      this.$refs.addAttributeComponent.clearDataFilter()
    },
    checkMoreFilterMethods (data) {
      this.$refs.filterComponent.changeChecked(data)
    },
    showProperty () {
      this.showPropertyFlag = !this.showPropertyFlag
      this.$refs.filterComponent.isCheckProperty = this.showPropertyFlag
      if (this.showPropertyFlag) {
        this.$refs.modelContentComponent.getSelection()
      } else {
        this.$refs.componentInfo.listData = []
      }
    },
    getInfo (guid) {
      this.$refs.componentInfo.getInfo(guid)
    },
    clearInfo () {
      console.log('清空数据')
      this.$refs.componentInfo.listData = []
    }
  }
}
</script>

<style lang="less" scoped>
	.model-page-content {
		display: flex;
		justify-content: space-between;
		position: relative;
		width: 100%;
		height: 908px;
		.left {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 999;
		}

		.bottom {
			position: absolute;
			left: 300px;
			bottom: 0;
			z-index: 999;
			transition: .5s;
			transition-timing-function: linear;
		}

		.model-div {
			position: absolute;
			width: 100%;
			height: 908px;
			left: 0;
			top: 0;
		}
	}
</style>
