<!-- 报表组件 -->
<template>
	<div class="page-content">
		<div class="content-box" :style="`width:${width}px`">
			<div class="table-left">
				<div class="title">
					<p>存储的报表</p>
				</div>
				<ul class="content">
					<li v-for="(item,index) of filterData" @click="checkFilterData(item,index)"
						:style="index== checkIndex?'background:#cccccc':''" :key="index">
						<div v-if="index != saveIndex" class="li-content">
							<p>{{item.title||item.reportName}}</p>
							<span class="el-icon-document-checked" @click.stop="saveReport(index)"></span>
							<span v-if="index != 0" class="el-icon-close" @click.stop="deleteTable(item)"></span>
						</div>
						<div class="li-save" v-else @click.stop>
							<el-input v-model="inputSaveValue" placeholder="请输入内容" size="mini" ref="saveInput"
								@click.stop></el-input>
							<el-button type="primary" size="mini" @click.stop="saveHandler">确定</el-button>
						</div>
					</li>
				</ul>
			</div>
			<div class="table-middle">
				<div class="title">
					<div>
						<el-button type="info" size="mini" @click="addAttribute">+属性</el-button>
						<!-- <el-button type="info" size="mini" @click="lookTable">查询</el-button> -->
					</div>
					<div>总数：<label>{{cloneDate.length}}</label></div>
					 <!-- 通过对数据的克隆进行总数比较，总长度以筛选后的值为准 -->
					<div>
						<el-button type="info" size="mini" @click="exportRevit">数据关联</el-button>
						<el-button type="info" size="mini" @click="exportExcel">导出EXCEL</el-button>
					</div>
				</div>
				<div class="content">
					<el-table v-loading="loadingTable" id="tableModel" :data="defaultData" style="width: 100%"
						:row-style="{background:'rgba(225, 225, 225, 0.9)'}" v-el-table-infinite-scroll="loadTable"
						height="282px" @row-click="rowClick" ref="table">
						<el-table-column v-for="(item,index) of defaultHeader" :key="index" align="center"
							v-if="index != 0" :label="item">
							<template slot-scope="scope">
								{{scope.row[item]}}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="table-right">
				<div class="title">
					<div>
						<el-button type="info" size="mini" @click="filterTable">高级过滤</el-button>
						<el-link type="primary" :underline="false" @click="clearFilter" style="margin-left:10px ;">清除过滤
						</el-link>
					</div>
					<!-- <el-link type="primary" :underline="false" slot="reference">+属性</el-link> -->
					<el-popover placement="right" width="400" trigger="click" popper-class="statementPopover"
						ref="popover">
						<el-tree show-checkbox node-key="label" :data="advancedData" :props="advancedProps"
							ref="advanceTree"></el-tree>
						<p style="text-align: right;margin-top: 10px;">
							<el-button type="danger" @click="closePopover" size="mini">取消</el-button>
							<el-button type="primary" size="mini" @click="sureAddAddvance">确定</el-button>
						</p>
						<el-link type="primary" :underline="false" slot="reference">+属性</el-link>
					</el-popover>
					<!-- <el-link type="primary" :underline="false">+属性</el-link> -->
				</div>
				<div class="content">
					<li class="content-title">
						<span>属性</span>
						<span>条件</span>
						<span>值</span>
					</li>
					<ul class="advance">
						<li v-for="(item,index) of advanceFilterData" :key="index">
							<span>{{item.label}}</span>
							<span>
								<!-- <el-select size="mini" v-model="item.selectValue" placeholder="请选择"
									@change="changeSelect(item)">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
									<el-option></el-option>
								</el-select> -->
								<el-cascader
								    v-model="item.selectValue"
								    :options="options"
									size="mini"
									:show-all-levels="false"
								    :props="{ expandTrigger: 'hover' }"
								    @change="changeSelect(item)"></el-cascader>
							</span>
							<span>
								 <el-date-picker
								  v-if="item.selectValue && item.selectValue.length==2"
                                  v-model="item.inputValue"
                                  placeholder="选择日期时间"
								  format="yyyy/MM/dd"
								  size="mini"
								  style="width: 100px;"
								  >
                               </el-date-picker>

								<el-input
								    v-else
									size="mini"
									v-model="item.inputValue"
									placeholder="请输入内容"
									@blur="changeSelect(item)">
								</el-input>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
  Loading
} from 'element-ui'
import {
  mapState,
  mapMutations
} from 'vuex'
import EventBusModel from './eventBus/eventBusModel.js'
// import TiemFiltration from './timeFiltration'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
let loading // 定义loading变量
function startLoading () { // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '报表正在生成中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
export default {
  name: 'Statement',
  props: ['width'],
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  data () {
    return {
      filterData: [{
        title: '默认报表',
        filterList: [{
          id: 1,
          name: 'ID'
        }, {
          id: 2,
          name: '构建类型'
        }, {
          id: 3,
          name: '楼层'
        }]
      }],
      checkIndex: -1,
      options: [{
        value: 1,
        label: '等于'
      }, {
        value: 2,
        label: '大于'
      }, {
        value: 3,
        label: '小于'
      }, {
        value: 4,
        label: '大于等于'
      }, {
        value: 5,
        label: '小于等于'
      }, {
        value: 6,
        label: '包含'
      }, {
        value: 7,
        label: '时间',
        children: [{
          value: 71,
          label: '时间等于'
        }, {
          value: 72,
          label: '时间大于'
        }, {
          value: 73,
          label: '时间小于'
        }, {
          value: 74,
          label: '时间大于等于'
        }, {
          value: 75,
          label: '时间小于等于'
        }]
      }],
      selectValue: '',
      inputValue: '',
      tableHeader: [],
      tableDataList: [],
      defaultData: [],
      saveIndex: -1,
      inputSaveValue: '',
      tableList: [],
      defaultHeader: [],
      deleteList: [],
      advancedData: [],
      advancedProps: {
        children: 'children',
        label: 'label'
      },
      advanceFilterData: [],
      report: '', // 当前正在编辑的报表
      floorName: '楼层',
      spaceName: '空间',
      copyDetail: {},
      filterDataList: [], // 高级过滤中存储的过滤条件
      familyAndTypeName: '族与类型',
      currentTableName: '默认报表',
      loadingTable: false,
      loadIndex: 0,
      cloneDate: []// 克隆后的数据
    }
  },
  mounted () {
    this.checkFilterData('', 0)
    this.getTableList()

    EventBusModel.$on('lookTable', () => {
      this.lookTable()
    })
    EventBusModel.$on('clearTableData', () => {
      this.checkFilterData({
        title: '默认报表'
      }, 0, 1)
    })
  },
  computed: {
    ...mapState('bimModel', {
      filterChecked: 'filterChecked',
      reviewData: 'reviewData',
      modelData: 'modelData',
      floorChecked: 'floorChecked',
      spaceChecked: 'spaceChecked',
      floorData: 'floorData',
      spaceData: 'spaceData',
      familyAndType: 'familyAndType',
      cacheState: 'cacheState',
      categoryData: 'categoryData'
    })
  },
  watch: {},
  methods: {
    ...mapMutations('bimModel', {
      initAutoHeader: 'initAutoHeader',
      initMoreFilterData: 'initMoreFilterData',
      initFilterChecked: 'initFilterChecked',
      initReviewData: 'initReviewData',
      initFloorChecked: 'initFloorChecked',
      initSpaceChecked: 'initSpaceChecked',
      initMoreFilterChecked: 'initMoreFilterChecked',
      initFamilyAndType: 'initFamilyAndType',
      initFilterObj: 'initFilterObj',
      initAllGuid: 'initAllGuid'
    }),
    exportExcel () {
      let name = localStorage.getItem('projectName') + '_' + (this.currentTableName ? this.currentTableName
        : '默认报表')
      this.$utils.exportExcel('#tableModel', name)
    },
    exportRevit () {
      EventBusModel.$emit('exportRevit')
    },
    async lookTable () {
      this.loadingTable = true
      this.defaultData = []
      this.tableDataList = []
      let showGuidList = []
      this.loadIndex = 0
      if (this.checkIndex != 0) {
        await EventBusModel.$emit('autoCheckedReview')
      }
      // 初始化一下头部
      this.tableHeader = ['guid', 'elementId']
      // 初始一下行的数据
      let list = []
      if (this.filterChecked.length) {
        let lastCheckedObj = null
        if (this.filterChecked.length) {
          lastCheckedObj = this.filterChecked[this.filterChecked.length - 1]
        }
        let list = []
        let elementIdList = []
        lastCheckedObj.propertyValues.map(e => {
          list.push(...e.elementGuid)
          elementIdList.push(...e.elementId)
        })
        for (let key in list) {
          this.tableDataList.push({
            guid: list[key],
            elementId: elementIdList[key]
          })
          showGuidList.push(list[key])
        }
      } else if (this.familyAndType.length) {
        let elementIdList = []
        this.familyAndType.map(item => {
          list = list.concat(item.elementGuid)
          elementIdList = elementIdList.concat(item.elementId)
        })
        for (let ind in list) {
          this.tableDataList.push({
            guid: list[ind],
            elementId: elementIdList[ind]
          })
          showGuidList.push(list[ind])
        }
      } else if (!this.familyAndType.length && this.spaceChecked.length) {
        // let list = []
        this.spaceChecked.map(item => {
          list.push(...item.spaceElementPropertyDtos)
        })
        list.map(e => {
          this.tableDataList.push({
            guid: e.elementGuid,
            elementId: e.elementId
          })
          showGuidList.push(e.elementGuid)
        })
      } else if (this.floorChecked.length && !this.spaceChecked.length && !this.familyAndType.length) {
        // let list = []
        this.floorChecked.map(item => {
          list.push(...item.floorElementPropertyDtos)
        })
        list.map(e => {
          this.tableDataList.push({
            guid: e.elementGuid,
            elementId: e.elementId
          })
          showGuidList.push(e.elementGuid)
        })
      }
      this.initAllGuid(JSON.parse(JSON.stringify(this.tableDataList)))
      this.cloneDate = JSON.parse(JSON.stringify(this.tableDataList))
      EventBusModel.$emit('checkedObj', showGuidList)
      this.defaultData = this.tableDataList.slice(0, 20)
      /// 主要是以下的方法执行时间太长
      // 生成报表的方法
      // 生成楼层和空间中的数据
      await this.filterChange(this.floorName, this.floorChecked, 'floorElementPropertyDtos', 'floorName',
        this.defaultData)
      await this.filterChange(this.spaceName, this.spaceChecked, 'spaceElementPropertyDtos',
        'modelSpaceName', this.defaultData)
      await this.familyAndTypeChange(this.familyAndType, this.defaultData)
      // 生成更多过滤中的数据
      await this.addTableList(this.filterChecked, this.defaultData)
      // 生成+属性中的数据
      await this.addTableList(this.reviewData, this.defaultData)
      // 统一过滤一下数据，如果选了更多过滤里面的东西，只要更多过滤里面最后一次的
      this.initAutoHeader(this.tableHeader)
      this.defaultHeader = JSON.parse(JSON.stringify(this.tableHeader))
      for (let index in this.defaultHeader) {
        let item = this.defaultHeader[index]
        if (item.split('@')[1] == 'E') {
          // 去找有没有另一个R类型的
          if (this.tableHeader.findIndex(e => e == item.split('@')[0] + '@R') == -1) {
            item = item.split('@')[0]
          }
        } else {
          // 去找有没有另一个E类型的
          if (this.tableHeader.findIndex(e => e == item.split('@')[0] + '@E') == -1) {
            item = item.split('@')[0]
          }
        }
        this.defaultHeader[index] = item
      }
      // 生成头部
      // 生成头部 在生成数据的时候同步进行
      // 3.生成行里面的数据生成
      let key = 0
      this.advancedData = []
      this.defaultHeader.map(e => {
        if (e != 'guid') {
          this.advancedData.push({
            label: e,
            id: key
          })
          key++
        }
      })
      this.loadingTable = false
      // guid:{注释:无,主体ID:-1,类型名称:无,楼层:1F}
    },
    // 操作族与类型中的数据用于生成table
    familyAndTypeChange (data, changeData) {
      if (data.length) {
        this.tableHeader.push(this.familyAndTypeName)
        data.map(item => {
          item.elementGuid.map(e => {
            // const findInd = this.defaultData.findIndex(a => a.guid == e)
            let obj = changeData.find(a => a.guid == e)
            if (obj) {
              // this.defaultData[findInd][this.familyAndTypeName] = item.propertyName
              obj[this.familyAndTypeName] = item.propertyName
            }
          })
        })
      }
    },
    // 根据标准数据往tableList中添加数据
    addTableList (data, changeData) {
      if (!data) {
        return
      }
      data.map(e => {
        this.tableHeader.push(e.propertyName)
        e.propertyValues.map(item => {
          for (let index in item.elementGuid) {
            // 去已经生成的数据里面找，找到了就添加进去
            let obj = changeData.find(p => p.guid == item.elementGuid[index])
            if (obj) {
              // 看一下数据中有没有另一个类型的，有的话名字后面+类型
              obj[e.propertyName] = item.value
            }
          }
        })
      })
    },
    async filterChange (name, data, id, n, changeData) {
      if (data.length) {
        this.tableHeader.push(name)
        for (let one of data) {
          let res = one[id]
          if (res.length) {
            for (let e of res) {
              let obj = changeData.find(a => a.guid == e.elementGuid)
              if (obj) {
                obj[name] = one[n]
              }
            }
          }
        }
      }
    },
    // 保存报表
    async saveReport (index) {
      if (!this.defaultHeader.length || index != this.checkIndex) {
        this.$message('请先查看数据，确定是否进行更改')
        return
      }
      if (index != 0) {
        // 生成要提交的数据
        // 1.获取删除的列
        // 删除列匹配的时候和初始的copyDetail进对比，但是这个时候不要忘了对copyDetail的数据进行处理，改删掉@的删掉@，该增加@的增加@
        let deletePropertyDatas = []
        this.copyDetail.reportProperties.map(e => {
          if (this.defaultHeader.findIndex(a => a == e.name || a == e.name.split('@')[0]) == -1) {
            deletePropertyDatas.push(e.name)
          }
        })
        // 2.获取增加的列
        let addColumList = []
        this.defaultHeader.map(e => {
          if (this.copyDetail.reportProperties.findIndex(a => a.name == e || e == a.name.split(
            '@')[0]) == -1 && e !=
							'guid') {
            addColumList.push(e)
          }
        })
        let addPropertyDatas = []
        // 看一下这个增加的列是哪里面的，然后给elementId相应的字段
        addColumList.map(e => {
          let obj = {}
          if (e == this.floorName) {
            // 如果是楼层
            obj.name = this.floorName
            let list = []
            this.floorChecked.map(a => {
              list.push(a.floorGuid)
            })
            obj.addFilterElementId = list
          } else if (e == this.spaceName) {
            // 如果是空间
            obj.name = this.spaceName
            let list = []
            this.spaceChecked.map(a => {
              list.push(a.modelSpaceGuid)
            })
            obj.addFilterElementId = list
          } else if (this.filterChecked.findIndex(p => p.propertyName == e)) {
            // 如果是更多过滤里面的
            const o = this.filterChecked.find(a => a.propertyName == e)
            // 在这里给名字后面添加一个后缀
            obj.name = e + `@${o.type}`
            let list = []
            o.propertyValues.map(m => {
              list.push(m.elementId[0])
            })
            obj.addFilterElementId = list
          } else {
            // 除此之外只能是预览数据里面的
            obj.name = e
            obj.addFilterElementId = []
          }
          addPropertyDatas.push(obj)
        })
        // 生变编辑的数据
        // 1. 编辑的数据有可能是那种非新增、非删除、非guid、非elementId的情况,同时，新增不可能是+属性添加的，只有可能是更多过滤或者楼层以及空间
        let updateList = []
        this.copyDetail.reportProperties.map(e => {
          if (e.filterElementId.length && e.name != 'elementId' && e.name != 'guid' &&
							addColumList.findIndex(a => e.name == a) == -1 && deletePropertyDatas.findIndex(
            a =>
              e.name == a) == -1) {
            updateList.push(e)
          }
        })
        let updatePropertyDatas = []
        updateList.map(item => {
          // 如果是楼层
          if (item.name == this.floorName) {
            let changeList = []
            this.floorChecked.map(e => {
              changeList.push(e.floorGuid)
            })
            // 找被删除的
            let deleteList = []
            item.filterElementId.map(b => {
              if (changeList.findIndex(p => p == b) == -1) {
                deleteList.push(b)
              }
            })
            // 找增加
            let addList = []
            changeList.map(b => {
              if (item.filterElementId.findIndex(p => b == p) == -1) {
                addList.push(b)
              }
            })
            if (deleteList.length || addList.length) {
              let obj = {}
              obj.name = item.name
              obj.addFilterElementId = addList
              obj.deleteFilterElementId = deleteList
              updatePropertyDatas.push(obj)
            }
          } else if (item.name == this.spaceName) {
            let changeList = []
            this.spaceChecked.map(e => {
              changeList.push(e.modelSpaceGuid)
            })
            // 找被删除的
            let deleteList = []
            item.filterElementId.map(b => {
              if (changeList.findIndex(p => p == b) == -1) {
                deleteList.push(b)
              }
            })
            // 找增加
            let addList = []
            changeList.map(b => {
              if (item.filterElementId.findIndex(p => b == p) == -1) {
                addList.push(b)
              }
            })
            if (deleteList.length || addList.length) {
              let obj = {}
              obj.name = item.name
              obj.addFilterElementId = addList
              obj.deleteFilterElementId = deleteList
              updatePropertyDatas.push(obj)
            }
          } else if (item.name == this.familyAndTypeName) {
            let changeList = []
            this.familyAndType.map(e => {
              changeList.push(e.dbid)
            })
            // 找被删除的
            let deleteList = []
            item.filterElementId.map(b => {
              if (changeList.findIndex(p => p == b) == -1) {
                deleteList.push(`${b}`)
              }
            })
            // 找增加
            let addList = []
            changeList.map(b => {
              if (item.filterElementId.findIndex(p => b == p) == -1) {
                addList.push(`${b}`)
              }
            })
            if (deleteList.length || addList.length) {
              let obj = {}
              obj.name = item.name
              obj.addFilterElementId = addList
              obj.deleteFilterElementId = deleteList
              updatePropertyDatas.push(obj)
            }
          } else {
            // 现在只能是更多过滤里面的
            // 1.先去更多过滤里面拿出要的数据
            const filterObj = this.filterChecked.find(a => a.propertyName == item.name || a
              .propertyName == item.name.split('@')[0])
            let changeList = []
            filterObj.propertyValues.map(e => {
              changeList.push(e.elementId[0])
            })
            // 找被删除的
            let deleteList = []
            item.filterElementId.map(b => {
              if (changeList.findIndex(p => p == b) == -1) {
                deleteList.push(b)
              }
            })
            // 找增加
            let addList = []
            changeList.map(b => {
              if (item.filterElementId.findIndex(p => b == p) == -1) {
                addList.push(b)
              }
            })
            if (deleteList.length || addList.length) {
              let obj = {}
              obj.name = item.name
              obj.addFilterElementId = addList
              obj.deleteFilterElementId = deleteList
              updatePropertyDatas.push(obj)
            }
          }
        })
        let data = {
          reportGUID: this.report.reportGUID,
          reportName: this.report.reportName,
          addPropertyDatas: addPropertyDatas,
          updatePropertyDatas: updatePropertyDatas,
          deletePropertyDatas: deletePropertyDatas
        }
        this.$axios.post('/api/formhandle/creatorupdatereportform', data).then(res => {
          this.$message.success('保存成功')
        })
      } else {
        if (!this.defaultData.length) {
          this.$message.warning('当前暂无默认数据')
          return
        }
        this.inputSaveValue = ''
        this.saveIndex = index
        setTimeout(() => {
          this.$refs.saveInput[0].focus()
        }, 1)
      }
    },
    saveHandler () {
      if (!this.inputSaveValue) {
        this.$message.warning('当前报表名称不得为空')
        return
      }
      const data = {
        ReportName: this.inputSaveValue
      }
      const addPropertyDatas = []
      this.tableHeader.map(item => {
        // 不传guid列
        if (item != 'guid') {
          addPropertyDatas.push({
            name: item,
            addFilterElementId: []
          })
        }
      })
      addPropertyDatas.forEach(item => {
        const find = this.filterChecked.find(e => e.propertyName == item.name.split('@')[0] || e
          .propertyName == item.name)
        if (find) {
          if (item.name.split('@').length == 1) {
            item.name = item.name + `@${find.type}`
          }
          find.propertyValues.map(q => {
            item.addFilterElementId.push(q.elementId[0])
          })
        }
        if (item.name == this.floorName) {
          let ids = []
          this.floorChecked.map(a => {
            ids.push(a.floorGuid)
          })
          item.addFilterElementId = ids
        }
        if (item.name == this.spaceName) {
          let ids = []
          this.spaceChecked.map(a => {
            ids.push(a.modelSpaceGuid)
          })
          item.addFilterElementId = ids
        }
        if (item.name == this.familyAndTypeName) {
          let ids = []
          this.familyAndType.map(a => {
            ids.push(`${a.dbid}`)
          })
          item.addFilterElementId = ids
        }
      })
      data.addPropertyDatas = addPropertyDatas
      // 调用保存报表的API
      this.$axios.post('/api/formhandle/creatorupdatereportform', data).then(res => {
        this.$message.success('保存成功')
        this.saveIndex = -1
        this.getTableList()
      })
    },
    async checkFilterData (item, index, type) {
      this.currentTableName = item.title || item.reportName
      // 采用事件总线传递清空方法
      if (!type) {
        await this.$emit('clearData')
      }
      await this.initMoreFilterChecked([])
      this.defaultData = []
      this.defaultHeader = []
      await this.initFilterChecked([])
      await this.initAutoHeader([])
      await this.initFloorChecked([])
      await this.initSpaceChecked([])
      await this.initFamilyAndType([])
      await this.initReviewData([])
      await this.initMoreFilterData([])
      await this.initFilterObj({})
      this.tableDataList = []
      // 点击的是默认报表列的时候
      setTimeout(() => {
        if (!index) {
          this.copyDetail = {}
        } else {
          if (!this.cacheState) {
            this.$message.warning('数据正在初始化，请稍候')
            return
          }
          startLoading()
          const reportGuid = item.reportGUID
          this.report = JSON.parse(JSON.stringify(item))
          this.getTableDetail(reportGuid)
        }
        this.checkIndex = index
        // 为高级过滤重新赋值
        // this.filterNow = {}
        this.filterDataList = []
      }, 10)
    },
    rowClick (val) {
      EventBusModel.$emit('selectElement', [val.guid])
    },
    // 点击增加属性按钮
    addAttribute () {
      if (!this.cacheState) {
        this.$message.warning('数据正在初始化，请稍候')
        return
      }
      EventBusModel.$emit('addAttribute')
    },
    // 获取报表列表
    getTableList () {
      this.$axios.get(`/api/formhandle/getreportformlist`)
        .then(res => {
          this.tableList = res
          this.filterData.splice(1)
          this.filterData.push(...this.tableList)
        })
    },
    getFloorChecked (yuansiData, checkData) {
      let data = yuansiData.filter(item => checkData[0].filterElementId.findIndex(e => e == item.floorGuid) != -1)
      return data
    },
    filterSpaceData (yuansiData, guidList) {
      yuansiData.forEach(item => {
        item.spaceElementPropertyDtos = item.spaceElementPropertyDtos.filter(a => {
          return guidList.findIndex(p => p == a.elementGuid) != -1
        })
        item.spaceElementCount[1] = item.spaceElementPropertyDtos.length
      })
      yuansiData = yuansiData.filter(a => {
        return a.spaceElementPropertyDtos.length
      })
      return yuansiData
    },
    filterFamilyAndType (yuansiData, guidList) {
      // 用递归的方法进行筛选
      function getCount1 (item, arr) {
        let count = 0
        // 遍历整个数组
        for (let i = 0; i < item.length; i++) {
          // 如果有children有值则向下递归
          if (item[i].children.length !== 0) {
            // 该元素的count为下一层的count的和
            item[i].count = getCount1(item[i].children, arr)
          } else {
            // 过滤
            item[i].elementList = item[i].elementList.filter(a => {
              return arr.findIndex(b => b === a.elementGuid) !== -1
            })
            item[i].count = item[i].elementList.length
          }
          item[i].elementCount[1] = item.count
          // 累加本层count
          count = count + item[i].count
          // 删除count为0的元素
          if (item[i].count === 0) {
            item.splice(i, 1)
            // 删除后索引改变，需要减1
            i--
          }
        }
        return count
      }

      function getCount (item) {
        let count = 0
        // 遍历整个数组
        for (let i = 0; i < item.length; i++) {
          // 如果有children有值则向下递归
          if (item[i].children.length !== 0) {
            // 该元素的count为下一层的count的和
            item[i].count = getCount(item[i].children)
          }
          // 累加本层count
          count = count + item[i].count
          item[i].elementCount[1] = item[i].count
          // 删除count为0的元素
          if (item[i].count === 0) {
            item.splice(i, 1)
            // 删除后索引改变，需要减1
            i--
          }
        }
        return count
      }
      getCount1(yuansiData, guidList)
      getCount(yuansiData)
      return yuansiData
    },
    getSpaceChecked (yuansiData, checkData) {
      let data = yuansiData.filter(item => checkData[0].filterElementId.findIndex(e => e == item
        .modelSpaceGuid) != -1)
      return data
    },
    getFamilyAndTypeChecked (yuansiData, checkData) {
      // 族与类型是有层级结构的，所以这里需要用递归的方式去查找一下族与类型中选中的项
      let datas = []

      function getChecked (data, checkData) {
        data.map(item => {
          if (checkData.findIndex(did => item.dbid == did) != -1) {
            datas.push(item)
          }
          if (item.children.length) {
            return getChecked(item.children, checkData)
          }
        })
      }
      getChecked(yuansiData, checkData)
      return datas
    },
    filterAllMoreFilterData (yuansiData, guidList) {
      let data = yuansiData
      for (let index in data) {
        if (guidList.length) {
          data[index].propertyValues.forEach(item => {
            item.elementGuid = item.elementGuid.filter(a => guidList.findIndex(w => w == a) !=
								-1)
            item.valueCount[1] = item.elementGuid.length
            let elementIdList = []
            item.elementGuid.map(l => {
              let i = item.elementGuid.findIndex(m => m == l)
              if (i != -1) {
                elementIdList.push(item.elementId[i])
              }
            })
            item.elementId = elementIdList
          })
        }
      }
      return data
    },
    getMoreFilterChecked (yuansiData, checkData) {
      let copyYuansiData = JSON.parse(JSON.stringify(yuansiData))
      let data = []
      copyYuansiData.propertyValues = copyYuansiData.propertyValues.filter(item => checkData.findIndex(e => e ==
					item.elementId[0]) != -1)
      return copyYuansiData
    },
    filterMoreFilterData (yuansiData, guidList, yuansiIndex) {
      let data = yuansiData
      for (let index in data) {
        if (guidList.length && index > yuansiIndex) {
          data[index].propertyValues.forEach(item => {
            item.elementGuid = item.elementGuid.filter(a => guidList.findIndex(w => w == a) !=
								-1)
            item.valueCount[1] = item.elementGuid.length
            let elementIdList = []
            item.elementGuid.map(l => {
              let i = item.elementGuid.findIndex(m => m == l)
              if (i != -1) {
                elementIdList.push(item.elementId[i])
              }
            })
            item.elementId = elementIdList
          })
        }
      }
      return data
    },
    async getTableDetail (reportGuid) {
      try {
        let res = await this.$axios.get(`/api/formhandle/getreportformdata?reportGuid=${reportGuid}`)
        this.copyDetail = JSON.parse(JSON.stringify(res[0]))
        // 重写还原报表相关代码
        // 1. 根据返回的数据拿出楼层、空间、族与类型、更多过滤、+属性中的数据
        let apiFloorData = []
        let apiSpaceData = []
        let apiFamilyAndTypeData = []
        let apiMoreFilterData = []
        let apiAddAttributeData = []
        res[0].reportProperties.map(item => {
          // 拿出更多过滤中的选择项，这里name不能等于elmentId,楼层，空间，以及filterElementId不能为空，为空是+属性中的数据
          let name = item.name
          if (name != 'elementId' && name != this.floorName && name != this.spaceName && item
            .filterElementId.length && name != this.familyAndTypeName) {
            apiMoreFilterData.push(item)
          } else if (name == this.floorName) {
            apiFloorData.push(item)
          } else if (name == this.spaceName) {
            apiSpaceData.push(item)
          } else if (name == this.familyAndTypeName) {
            apiFamilyAndTypeData = item.filterElementId
          } else if (!item.filterElementId.length && name != 'elementId') {
            apiAddAttributeData.push(item)
          }
        })
        // 判断有没有楼层选中项，有就拿出选中的数据，因为楼层是最高层级不会被任何筛选所以不用考虑筛选问题
        let newFloorData = [] // 新的楼层数据
        let newSpaceData = [] // 新的空间数据
        let newFamilyAndTypeData = [] // 新的族与类型数据
        let newMoreFilterData = [] // 新的更多过滤数据
        let checkFloorData = [] // 选中的楼层数据
        let checkSpaceData = [] // 选中的空间数据
        let checkFamilyAndTypeData = [] // 选中的族与类型数据
        let checkMoreFilterData = [] // 选中的更多过滤数据
        let checkAddAttributeData = [] // 选中的+属性数据
        let checkFloorGuidDatas = []
        let checkSpaceGuidDatas = []
        let checkFamilyAndTypeGuidDatas = []
        // 为了杜绝对源数据造成影响，这里深克隆一下空间和族与类型中的数据
        let copySpaceData = JSON.parse(JSON.stringify(this.spaceData))
        let copyFamilyAndTypeData = JSON.parse(JSON.stringify(this.categoryData))
        if (apiFloorData.length) {
          checkFloorData = this.getFloorChecked(this.floorData, apiFloorData)
          // 拿出选中的楼层中的数据之后，需要对空间和族与类型中的数据进行过滤，注意，这里如果空间中有被选中的项目，空间也会对族与类型进行过滤，因此这个步骤就重复了，所以先判定一下空间中有没有选中项，如果有就不用对族与类型中的数据进行过滤
          // 1. 拿出所有被选中项的guid
          checkFloorData.map(e => {
            e.floorElementPropertyDtos.map(item => {
              checkFloorGuidDatas.push(item.elementGuid)
            })
          })
          // 执行空间数据的过滤
          newSpaceData = this.filterSpaceData(copySpaceData, checkFloorGuidDatas)
          // 判断一下空间中是否有选中项
          // 如果有就不去做筛选，如果没有就去筛选
          if (!apiSpaceData.length) {
            // 空间中没有选择项，去筛选族与类型
            newFamilyAndTypeData = this.filterFamilyAndType(copyFamilyAndTypeData, checkFloorGuidDatas)
          } else {
            newFamilyAndTypeData = copyFamilyAndTypeData
          }
          // 至此和楼层数据相关的操作已经执行完毕，接下来开始看空间的相关操作
        } else {
          // 如果没有执行过滤就给新的空间数据和新的族与类型数据赋值为原始数据
          newSpaceData = copySpaceData
          newFamilyAndTypeData = copyFamilyAndTypeData
        }
        // 如果返回的数据中有楼层列相关的数据。那就去拿出楼层中选中的，并执行对族与类型的筛选等操作
        if (apiSpaceData.length) {
          checkSpaceData = this.getSpaceChecked(newSpaceData, apiSpaceData)
          // 拿出所有的Guid
          checkSpaceData.map(e => {
            e.spaceElementPropertyDtos.map(item => {
              checkSpaceGuidDatas.push(item.elementGuid)
            })
          })
          // 执行对族与类型数据的筛选
          newFamilyAndTypeData = this.filterFamilyAndType(copyFamilyAndTypeData, checkSpaceGuidDatas)
        } // 这里因为在楼层中如果有选中的，空间和族与类型已经有初始值了，如果没有也有初始值了，所以这里只用有选中的空间才需要对族与类型中的数据去处理，如果没有选中就还是之前的值，这里不用管
        // 如果返回的数据中有族与类型相关的数据，那就去拿出族与类型中的选中的数据
        if (apiFamilyAndTypeData.length) {
          checkFamilyAndTypeData = this.getFamilyAndTypeChecked(newFamilyAndTypeData,
            apiFamilyAndTypeData)
          checkFamilyAndTypeData.map(item => {
            item.elementList.map(e => {
              checkFamilyAndTypeGuidDatas.push(e.elementGuid)
            })
          })
        }
        // 开始针对更多过滤和+属性中的数据进行处理
        let eList = [] // emdata的数据
        let rList = [] // revit的数据
        apiMoreFilterData.map(item => {
          let name = item.name.split('@')
          if (name[1] == 'E') {
            eList.push(name[0])
          } else {
            rList.push(name[0])
          }
        })
        apiAddAttributeData.map(item => {
          let name = item.name.split('@')
          if (name[1] == 'E') {
            eList.push(name[0])
          } else {
            rList.push(name[0])
          }
        })
        let listEmdata = []
        let listRevit = []
        if (eList.length) {
          listEmdata = await this.$axios.post('/api/formhandle/getformproperiesbypropertyname_cache',
            eList)
        }
        if (rList.length) {
          listRevit = await this.$axios.post('/api/modeldata/cache_modelproperiesbyproname', rList)
        }
        // 拿出更多过滤中的数据
        apiMoreFilterData.map(e => {
          let name = e.name.split('@')
          let obj = {}
          if (name[1] == 'E') {
            let revits = JSON.parse(JSON.stringify(listRevit))
            obj = JSON.parse(JSON.stringify(listEmdata)).find(item => item.propertyName ==
								name[0])
            if (revits.findIndex(item => item.propertyName == name[0]) != -1) {
              obj.propertyName = `${obj.propertyName}@E`
            }
            obj.type = 'E'
          } else {
            let emdatas = JSON.parse(JSON.stringify(listEmdata))
            obj = JSON.parse(JSON.stringify(listRevit)).find(item => item.propertyName == name[
              0])
            if (emdatas.findIndex(item => item.propertyName == name[0]) != -1) {
              obj.propertyName = `${obj.propertyName}@R`
            }
            obj.type = 'R'
          }
          newMoreFilterData.push(obj)
        })
        // 备份一份原始更多过滤中的数据
        let yuansiMoreFilterData = JSON.parse(JSON.stringify(newMoreFilterData))
        // 更多过滤中的数据，如果族与类型中有选中的，就用族与类型中的过滤，如果空间中有选中的就用空间中的过滤，如果楼层中有选中的就用楼层中的过滤
        if (checkFamilyAndTypeGuidDatas.length) {
          // 如果有族与类型
          newMoreFilterData = this.filterAllMoreFilterData(newMoreFilterData,
            checkFamilyAndTypeGuidDatas)
        } else if (checkSpaceGuidDatas.length) {
          // 如果有空间
          newMoreFilterData = this.filterAllMoreFilterData(newMoreFilterData, checkSpaceGuidDatas)
        } else if (checkFloorGuidDatas.length) {
          // 如果有楼层
          newMoreFilterData = this.filterAllMoreFilterData(newMoreFilterData, checkFloorGuidDatas)
        }
        if (newMoreFilterData.length) {
          // 先拿去第一个的，过滤后面的，然后再拿出下一个，再继续过滤后面的
          for (let index in newMoreFilterData) {
            let data = this.getMoreFilterChecked(newMoreFilterData[index], apiMoreFilterData[index]
              .filterElementId)
            checkMoreFilterData.push(data)
            let guidList = []
            data.propertyValues.map(e => {
              guidList.push(...e.elementGuid)
            })
            newMoreFilterData = this.filterMoreFilterData(newMoreFilterData, guidList, index)
          }
        }
        // 拿出+属性中的数据
        apiAddAttributeData.map(item => {
          let name = item.name.split('@')
          let obj = {}
          if (name[1] == 'E') {
            obj = listEmdata.find(item => item.propertyName == name[0])
            obj.type = 'E'
          } else if (name[1] == 'R') {
            obj = listRevit.find(item => item.propertyName == name[0])
            obj.type = 'R'
          }
          checkAddAttributeData.push(obj)
        })
        // 把相应数据放入vuex中
        // checkMoreFilterData需要处理之后才能放入vuex
        let checkFamilyAndTypeDataVuex = []
        checkFamilyAndTypeData.map(item => {
          let obj = {}
          obj.dbid = item.dbid
          obj.propertyName = item.label
          let elementList = []
          let guidList = []
          item.elementList.map(e => {
            guidList.push(e.elementGuid)
            elementList.push(e.elementId)
          })
          obj.elementGuid = guidList
          obj.elementId = elementList
          checkFamilyAndTypeDataVuex.push(obj)
        })
        this.initFloorChecked(checkFloorData)
        this.initSpaceChecked(checkSpaceData)
        this.initFamilyAndType(checkFamilyAndTypeDataVuex)
        this.initFilterChecked(checkMoreFilterData)
        this.initReviewData(checkAddAttributeData)
        // 开始执行界面操作，还原数据
        // 执行数据渲染和选择事件
        EventBusModel.$emit('restoringPageData', newFloorData, checkFloorData, newSpaceData,
          checkSpaceData, newFamilyAndTypeData, checkFamilyAndTypeData, newMoreFilterData,
          checkMoreFilterData, yuansiMoreFilterData)
        // 开始选中模型
        let type = 0
        if (checkMoreFilterData.length) {
          type = 4
        } else if (checkFamilyAndTypeDataVuex.length) {
          type = 3
        } else if (checkSpaceData.length) {
          type = 2
        } else if (checkFloorData.length) {
          type = 1
        }
        EventBusModel.$emit('checkedObj', type)
        loading.close()
        this.lookTable()
      } catch (e) {
        this.$message.error('报表生成出错，请联系管理员。')
        loading.close()
      }
    },
    deleteTable (data) {
      this.$confirm('此操作将永久删除该报表，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(`/api/formhandle/deletereportform?reportGuid=${data.reportGUID}`).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getTableList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 高级过滤里面的确定事件
    sureAddAddvance () {
      const data = this.$refs.advanceTree.getCheckedNodes()
      this.advanceFilterData = data
      this.$refs.popover.doClose()
    },
    closePopover () {
      this.$refs.popover.doClose()
    },
    changeSelect (val) {
      if (this.filterDataList.findIndex(e => e.label == val.label) == -1) {
        this.filterDataList.push(val)
      }
    },
    filterMethods (val) {
      console.log(val)
      // selectValue 1是等于 2是大于 3是小于 4是大于等于 5是小于等于 6是包含
      const select = val.selectValue
      const value = val.label
      const inputValue = val.inputValue
      if (!inputValue) {
        return
      }
      if (select.length === 1) {
        let selectCommon = select[0]
        if (selectCommon == 1) {
          this.defaultData = this.defaultData.filter(item => item[value] == inputValue)
        } else if (selectCommon == 2) {
          this.defaultData = this.defaultData.filter(item => item[value] > inputValue)
        } else if (selectCommon == 3) {
          this.defaultData = this.defaultData.filter(item => item[value] < inputValue)
        } else if (selectCommon == 4) {
          this.defaultData = this.defaultData.filter(item => item[value] >= inputValue)
        } else if (selectCommon == 5) {
          this.defaultData = this.defaultData.filter(item => item[value] <= inputValue)
        } else if (selectCommon == 6) {
          this.defaultData = this.defaultData.filter(item => {
            return this.contain(item[value], inputValue)
          })
        }
      } else if (select.length === 2) {
        // 选择的是时间
        // 转换为毫秒
        const getSeletime = (new Date(inputValue)).getTime()

        let selectTime = select[1]

				     if (selectTime == 71) {
          this.defaultData = this.defaultData.filter(item =>
            (new Date(this.timeFiltr(item[value]))).getTime() == getSeletime)
					 } else if (selectTime == 72) {
          this.defaultData = this.defaultData.filter(item =>

            (new Date(this.timeFiltr(item[value]))).getTime() > getSeletime)
					 } else if (selectTime == 73) {
          this.defaultData = this.defaultData.filter(item =>

            (new Date(this.timeFiltr(item[value]))).getTime() < getSeletime
						 )
					 } else if (selectTime == 74) {
          this.defaultData = this.defaultData.filter(item =>

						  (new Date(this.timeFiltr(item[value]))).getTime() >= getSeletime
						  )
					 } else if (selectTime == 75) {
          this.defaultData = this.defaultData.filter(item =>
						   (new Date(this.timeFiltr(item[value]))).getTime() <= getSeletime
						   )
					 }
      }
				  this.cloneDate = JSON.parse(JSON.stringify(this.defaultData))
    },
    // 数据过滤
			 timeFiltr (asxx) {
				  let s = asxx
				  if (s != '(空)' && s != null) {
        let x = s.replace(new RegExp('[^0-9]', 'gm'), '/')
				       let a = x.replace(new RegExp('/$', 'gm'), '')
				       let str = a.split('')
        if (x[6] == '/') {
				       str.splice(5, 0, 0)
				    }
				  if (str.join('').length == 9) {
          str.splice(8, 0, 0)
				  }
				   return str.join('')
			 }
    },
    // 模糊匹配
    contain (original, val) {
      var reg = new RegExp(val)
      return reg.test(original)
    },
    // 高级过滤
    filterTable () {
      this.filterDataList.map(e => {
        this.filterMethods(e)
      })
    },
    // 清除过滤
    clearFilter () {
      this.cloneDate = JSON.parse(JSON.stringify(this.tableDataList))
      // 克隆数据
      this.defaultData = JSON.parse(JSON.stringify(this.tableDataList))
    },
    // 表格滚动
    async loadTable () {
      if (this.defaultData.length >= this.tableDataList.length) {
        return
      }
      this.loadIndex++
      let startIndex = this.loadIndex * 20
      let endIndex = (this.loadIndex + 1) * 20
      if (endIndex - this.tableDataList.length > 0) {
        endIndex = this.tableDataList.length
      }
      let addData = this.tableDataList.slice(startIndex, endIndex)
      await this.filterChange(this.floorName, this.floorChecked, 'floorElementPropertyDtos', 'floorName',
        addData)
      await this.filterChange(this.spaceName, this.spaceChecked, 'spaceElementPropertyDtos',
        'modelSpaceName', addData)
      await this.familyAndTypeChange(this.familyAndType, addData)
      // 生成更多过滤中的数据
      await this.addTableList(this.filterChecked, addData)
      // 生成+属性中的数据
      await this.addTableList(this.reviewData, addData)
      this.defaultData = this.defaultData.concat(addData)
    }
  }
}
</script>

<style scoped lang="less">
	@import '../../../assets/css/model.less';
	.page-content {
		/deep/.el-input__inner {
			background-color: @backgroundColor;
			color: @fontColor;
		}

		/deep/.el-input__inner:focus {
			border-color: @borderColor;
		}

		/deep/.el-select .el-input.is-focus .el-input__inner {
			border-color: @backgroundColor;
		}

		/deep/.el-select .el-input__inner:focus {
			border-color: @backgroundColor;
		}

		/deep/.el-select-dropdown__item.selected {
			color: @backgroundColor;
		}

		/deep/.el-input.is-active .el-input__inner,
		.el-input__inner:focus {
			border-color: @backgroundColor;
		}

		/deep/.el-table th>.cell {
			font-weight: 500;
			color: @fontColor;
		}

		/deep/.el-table thead {
			background-color: @backgroundColor  !important;
		}

		/deep/.el-table th {
			background-color: @backgroundColor  !important;
		}

		/deep/.el-table__body-wrapper,
		.el-table__empty-block,
		.el-table__empty-text {
			background-color: @backgroundColor  !important;
		}

		/deep/.el-table th>.cell {
			font-size: 16px;
			padding: 0px 0;
		}

		/deep/.el-table th {
			padding: 7px 0;
		}

		/deep/.el-table td {
			padding: 0px 0;
		}

		/deep/.el-table,
		.el-tble_expanded-cell {
			background-color: @backgroundColor  !important;
			::-webkit-scrollbar{
				width: 10px;
			}
			::-webkit-scrollbar-thumb{
				border-radius:4px;
				height: 36px;
				-webkit-box-shadow:inset 0 0 5px rgb(187, 179, 179);
				background: rgb(241, 241, 241);
			}
			::-webkit-scrollbar-track{
				height: 24px;
				-webkit-box-shadow:inset 0 0 5px rgba(112, 109, 109, 0);
				border-radius:2px;
				background: rgb(173, 173, 173);
			}
		}

		/deep/.el-table .cell {
			font-size: 14px;
			color: @fontColor;
		}

		/deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
			background-color: #dcdcdc;
		}

		/deep/.el-popover {
			background: @backgroundColor  !important;
		}

		/deep/.el-table__body-wrapper {
			height: 244px !important;
		}
		.content-box {
			display: flex;
			// width: 1320px;
			height: 320px;
			background-color: @backgroundColor;
			border-top: 1px solid @borderColor;
			color: @fontColor;
			transition: .5s;
			transition-timing-function: linear;

			// background-color: #000066;
			.table-left {
				width: 15%;
				height: 100%;

				.title {
					display: flex;
					font-size: 16px;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					padding: 11px 10px;
					border-bottom: 1px solid @borderColor;
				}

				.content {
					height: 280px;
					overflow-y: auto;

					li {
						.li-content {
							display: flex;
							justify-content: space-between;
							padding: 7.5px 10px;
							font-size: 16px;
							cursor: pointer;
						}

						p {
							// background-color: #000066;
							text-align: left;
							width: calc(100% - 20px);
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						span {
							display: none;
							color: #02a7f0;
						}

						.li-save {
							display: flex;
							padding: 3px;

							/deep/.el-button--primary {
								background-color: #2f374a;
								border-color: #C0C4CC;
								margin-left: 5px;
							}
						}
					}

					li:hover span {
						display: block;
					}
				}
			}

			.table-middle {
				width: 60%;
				height: 100%;
				border-left: 1px solid @borderColor;
				border-right: 1px solid @borderColor;

				.title {
					display: flex;
					font-size: 16px;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					padding: 5px 10px;
					border-bottom: 1px solid @borderColor;
				}

			}

			.table-right {
				width: 25%;
				height: 100%;

				.title {
					display: flex;
					font-size: 16px;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
					padding: 5px 10px;
					border-bottom: 1px solid @borderColor;
				}

				.content {
					li {
						display: flex;
						justify-content: space-around;
						// box-sizing: border-box;
						height: 30px;
						align-items: center;
						border-bottom: 1px solid @borderColor;

						// padding: 5px 15px;
						span {
							width: 33%;
							display: inline-block;
							height: 30px;
							line-height: 30px;
						}

						// width: 100%;
						// background-color: #000066;
					}

					.content-title {
						span:nth-child(2) {
							border-left: 1px solid @borderColor;
							border-right: 1px solid @borderColor;
						}
					}

					.advance {
						height: 250px;
						overflow-y: auto;
					}
				}
			}
		}
	}
</style>
